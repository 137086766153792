import React from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { userLogout } from "../redux/feature/authSlice";

const ProtectedRoute = ({
  isAuthenticated,
  children,
  adminRoute,
  VendorRoute,
  isAdmin,
  isUser,
  redirect = "/",
  redirectAdmin = "/",
}) => {
  // let dispatch = useDispatch();

  if (!isAuthenticated) {
    return <Navigate to={redirect} />;
  }

  if (adminRoute && !isAdmin) {
    // dispatch(userLogout());
    return <Navigate to={redirectAdmin} />;
  }

  if (VendorRoute && !isUser) {
    // dispatch(userLogout());
    return <Navigate to={redirectAdmin} />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
