import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { baseUrlImage } from "../../../utils/config";

const Sidenav = () => {
  const { auth } = useSelector((state) => state.auth);
  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a href="#" className="nav-link">
              <div className="nav-profile-image">
                <img
                  src={
                    auth?.image !== ""
                      ? baseUrlImage + "" + auth?.image
                      : "/assets/images/faces/face1.jpg"
                  }
                  alt="idmage"
                />
                <span className="login-status online"></span>
              </div>
              <div className="nav-profile-text d-flex flex-column">
                <span className="font-weight-bold mb-2">{auth?.name}</span>
              </div>
              {/* <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i> */}
            </a>
          </li>

          <NavLink className="nav-item text-decoration-none" to="dashboard">
            <Link className="nav-link" to="/admin/dashboard">
              <span className="menu-title">Dashboard</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </NavLink>

          <NavLink className="nav-item text-decoration-none" to="user">
            <Link className="nav-link" to="/admin/user">
              <span className="menu-title">Users</span>
              <i className="mdi mdi-account menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="shopkeeper">
            <Link className="nav-link" to="/admin/shopkeeper">
              <span className="menu-title">Shopkeeper</span>
              <i className="mdi mdi-account-star menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="banner">
            <Link className="nav-link " to="/admin/banner">
              <span className="menu-title">Banner</span>
              <i className="mdi mdi-arrange-bring-forward menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="category">
            <Link className="nav-link " to="/admin/category">
              <span className="menu-title">Categories</span>
              <i className="mdi mdi-arrange-bring-forward menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="menu">
            <Link className="nav-link" to="/admin/menu">
              <span className="menu-title">Menu</span>
              <i className="mdi mdi-account-star menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="coupon">
            <Link className="nav-link" to="/admin/coupon">
              <span className="menu-title">Coupon</span>
              <i className="mdi mdi-vector-selection menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="order">
            <Link className="nav-link" to="/admin/order">
              <span className="menu-title">Orders</span>
              <i className="mdi mdi-weight menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="transaction">
            <Link className="nav-link" to="/admin/transaction">
              <span className="menu-title">Transaction</span>
              <i className="mdi mdi-credit-card-multiple menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="withdraw">
            <Link className="nav-link" to="/admin/withdraw">
              <span className="menu-title">Withdrawal Request</span>
              <i className="mdi mdi-weight menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="report">
            <Link className="nav-link" to="/admin/report">
              <span className="menu-title">Report</span>
              <i className="mdi mdi-chart-bubble menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="feed">
            <Link className="nav-link" to="/admin/feed">
              <span className="menu-title">Feed</span>
              <i className="mdi mdi-cube menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="review">
            <Link className="nav-link" to="/admin/review">
              <span className="menu-title">Review</span>
              <i className="mdi mdi-star menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="faq">
            <Link className="nav-link" to="/admin/faq">
              <span className="menu-title">Faq</span>
              <i className="mdi mdi-note-text menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="page">
            <Link className="nav-link" to="/admin/page">
              <span className="menu-title">Pages</span>
              <i className="mdi mdi-content-duplicate menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="contact">
            <Link className="nav-link" to="/admin/contact">
              <span className="menu-title">Contact Us</span>
              <i className="mdi mdi-contact-mail menu-icon"></i>
            </Link>
          </NavLink>
        </ul>
      </nav>
    </>
  );
};

export default Sidenav;
