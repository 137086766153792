import React, { useEffect, useState } from "react";
import BaseCard from "../../components/baseCard";
import LineChart from "../../components/Chart/LineChart";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalyticDashRes,
  getAnalyticsRes,
} from "../../../redux/feature/analyticSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [graph, setGraph] = useState();
  const [info, setInfo] = useState();

  useEffect(() => {
    dispatch(getAnalyticsRes(token)).then((value) => {
      if (value?.payload?.code === 200) {
        setGraph(value.payload.data);
      }
    });
    dispatch(getAnalyticDashRes(token)).then((value) => {
      if (value?.payload?.code === 200) {
        setInfo(value.payload.data);
      }
    });
  }, [dispatch, token]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Dashboard"} />
          <div className="row">
            <div className="col-md-4 stretch-card grid-margin">
              <div className="card bg-gradient-danger card-img-holder text-white">
                <div className="card-body">
                  <img
                    src="/assets/images/dashboard/circle.svg"
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Total Earning{" "}
                    <i className="mdi mdi-chart-line mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-5">${info?.totalEarning || 0}</h2>
                  {/* <h6 className="card-text">Increased by 60%</h6> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 stretch-card grid-margin">
              <div className="card bg-gradient-success card-img-holder text-white">
                <div className="card-body">
                  <img
                    src="/assets/images/dashboard/circle.svg"
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Total Orders{" "}
                    <i className="mdi mdi-diamond mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-5">{info?.totalOrder || 0}</h2>
                  {/* <h6 className="card-text">Increased by 5%</h6> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 stretch-card grid-margin">
              <div className="card bg-gradient-info card-img-holder text-white">
                <div className="card-body">
                  <img
                    src="/assets/images/dashboard/circle.svg"
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Total Menu{" "}
                    <i className="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-5">{info?.totalMenu || 0}</h2>
                  {/* <h6 className="card-text">Decreased by 10%</h6> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="clearfix">
                    <h4 className="card-title float-left">Traffic Sources</h4>
                    <LineChart graphData={graph} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
