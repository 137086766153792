import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  Box,
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BaseCard from "../../components/baseCard";
import { viewMenu } from "../../../redux/feature/menuSlice";
import { baseUrlImage } from "../../../utils/config";

const View = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { singleMenu } = useSelector((state) => state.menu);

  useEffect(() => {
    dispatch(viewMenu({ id, token }));
  }, [dispatch, id, token]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Menu/view"} link="/restaurant/menu" name="Back" />
          <Card className="mt-5">
            <CardContent>
              <div className="row w-100 gx-4 gy-5">
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                  Categories Name:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleMenu?.category_id?.title}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Menu Name:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleMenu?.title}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Price:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleMenu?.price}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Status:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleMenu?.status == 1 ? (
                      <Button variant="contained" color="success">
                        Active
                      </Button>
                    ) : (
                      <Button variant="contained" color="warning">
                        InActive
                      </Button>
                    )}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Created At:
                  </Typography>
                  <Typography variant="body2">
                    {moment(singleMenu?.createdAt).format("LL")}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="mt-5">
            <CardContent>
              <div className="col-md-4">
                <Typography className="w-100 mb-1" variant="body1">
                  Description:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {singleMenu?.description?.replace(/(<([^>]+)>)/gi, "")}
                </Typography>
              </div>
            </CardContent>
          </Card>
          <Card className="mt-5">
            <CardContent>
              <Typography className="w-100 mb-1" variant="body1">
                Images:
              </Typography>

              <Box>
                <Box sx={{ position: "relative" }}>
                  {singleMenu?.image?.map((val) => (
                    <img
                      src={baseUrlImage+val?.name}
                      width="200"
                      height="200"
                      alt="dfh"
                      className="col-md-2 p-1"
                    />
                  ))}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default View;
