import React, { useEffect, useState } from "react";
import BaseCard from "../../components/baseCard";
import LineChart from "../../components/Chart/LineChart";
import PieChart from "../../components/Chart/PieChart";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalyticDash,
  getAnalyticsUser,
} from "../../../redux/feature/analyticSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [graph, setGraph] = useState();
  const [info, setInfo] = useState();

  useEffect(() => {
    dispatch(getAnalyticsUser(token)).then((value) => {
      if (value.payload.code === 200) {
        setGraph(value.payload.data);
      }
    });
    dispatch(getAnalyticDash(token)).then((value) => {
      if (value.payload.code === 200) {
        setInfo(value.payload.data);
      }
    });
  }, [dispatch, token]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Dashboard"} />
          <div className="row">
            <div className="col-md-4 stretch-card grid-margin">
              <div className="card bg-gradient-danger card-img-holder text-white">
                <div className="card-body">
                  <img
                    src="/assets/images/dashboard/circle.svg"
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Total Users{" "}
                    <i className="mdi mdi-chart-line mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-5">{info?.totalUser || 0}</h2>
                  {/* <h6 className="card-text">Increased by 60%</h6> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 stretch-card grid-margin">
              <div className="card bg-gradient-info card-img-holder text-white">
                <div className="card-body">
                  <img
                    src="/assets/images/dashboard/circle.svg"
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Total shopkeeper{" "}
                    <i className="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-5">{info?.totalShop || 0}</h2>
                  {/* <h6 className="card-text">Decreased by 10%</h6> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 stretch-card grid-margin">
              <div className="card bg-gradient-success card-img-holder text-white">
                <div className="card-body">
                  <img
                    src="/assets/images/dashboard/circle.svg"
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Total Amount{" "}
                    <i className="mdi mdi-diamond mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-5">{info?.totalAmount || 0}</h2>
                  {/* <h6 className="card-text">Increased by 5%</h6> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 stretch-card grid-margin">
              <div className="card bg-gradient-danger card-img-holder text-white">
                <div className="card-body">
                  <img
                    src="/assets/images/dashboard/circle.svg"
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Total Admin Earning due{" "}
                    <i className="mdi mdi-chart-line mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-5">{info?.totalAdminCommissionDue.toFixed(2) || 0}</h2>
                  {/* <h6 className="card-text">Increased by 60%</h6> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 stretch-card grid-margin">
              <div className="card bg-gradient-info card-img-holder text-white">
                <div className="card-body">
                  <img
                    src="/assets/images/dashboard/circle.svg"
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                      Service Fee{" "}
                    <i className="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-5">{info?.totalTax.toFixed(2) || 0}</h2>
                  {/* <h6 className="card-text">Decreased by 10%</h6> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 stretch-card grid-margin">
              <div className="card bg-gradient-success card-img-holder text-white">
                <div className="card-body">
                  <img
                    src="/assets/images/dashboard/circle.svg"
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Total Admin Earning{" "}
                    <i className="mdi mdi-diamond mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-5">{info?.totalAdminCommission.toFixed(2) || 0}</h2>
                  {/* <h6 className="card-text">Increased by 5%</h6> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="clearfix">
                    <h4 className="card-title float-left">Traffic Sources</h4>
                    <div className="rounded-legend legend-horizontal legend-top-right float-right">
                      <LineChart graphData={graph} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Count Data</h4>
                  <div className="rounded-legend legend-vertical legend-bottom-left">
                    <PieChart dataInfo={info} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Recent Orders</h4>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th> Assignee </th>
                          <th> Subject </th>
                          <th> Status </th>
                          <th> Last Update </th>
                          <th> Tracking ID </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src="/assets/images/faces/face1.jpg"
                              className="me-2"
                              alt="images"
                            />{" "}
                            David Grey
                          </td>
                          <td> Fund is not recieved </td>
                          <td>
                            <label className="badge badge-gradient-success">
                              DONE
                            </label>
                          </td>
                          <td> Dec 5, 2017 </td>
                          <td> WD-12345 </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              src="/assets/images/faces/face2.jpg"
                              className="me-2"
                              alt="images"
                            />{" "}
                            Stella Johnson
                          </td>
                          <td> High loading time </td>
                          <td>
                            <label className="badge badge-gradient-warning">
                              PROGRESS
                            </label>
                          </td>
                          <td> Dec 12, 2017 </td>
                          <td> WD-12346 </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              src="/assets/images/faces/face3.jpg"
                              className="me-2"
                              alt="images"
                            />{" "}
                            Marina Michel
                          </td>
                          <td> Website down for one week </td>
                          <td>
                            <label className="badge badge-gradient-info">
                              ON HOLD
                            </label>
                          </td>
                          <td> Dec 16, 2017 </td>
                          <td> WD-12347 </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              src="/assets/images/faces/face4.jpg"
                              className="me-2"
                              alt="images"
                            />{" "}
                            John Doe
                          </td>
                          <td> Loosing control on server </td>
                          <td>
                            <label className="badge badge-gradient-danger">
                              REJECTED
                            </label>
                          </td>
                          <td> Dec 3, 2017 </td>
                          <td> WD-12348 </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
