import React from 'react';
import { Outlet } from "react-router-dom";
import Main from "../restaurants/components/layout/Main";


function RestaurantLayout(props) {
    return (
        <div>
            <Main>
                <Outlet />
            </Main>
        </div>

    )
}
export default RestaurantLayout;