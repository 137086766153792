import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_ORDER_DELETE, API_ORDER_LIST, API_ORDER_VIEW, API_TRANSACTION_LIST } from "../../utils/APIConstant";

export const getOrder = createAsyncThunk(
  "get-order",
  async ({ type, token }) => {
    const res = await axios.request({
      method: "get",
      url: API_ORDER_LIST,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const getTransaction = createAsyncThunk(
  "get-transaction",
  async (token) => {
    const res = await axios.request({
      method: "get",
      url: API_TRANSACTION_LIST,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const viewOrder = createAsyncThunk(
  "view-order",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "get",
      url: API_ORDER_VIEW + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const deleteOrder = createAsyncThunk(
  "delete-order",
  async ({ type, token }) => {
    const res = await axios.request({
      method: "get",
      url: API_ORDER_DELETE + "/" + type,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

const OrderSlice = createSlice({
  name: "order",
  initialState: {
    loading: false,
    error: null,
    order: [],
    singleOrder: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.order = action.payload.data;
    });
    builder.addCase(getOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(viewOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
      state.singleOrder = action.payload.data;
    });
    builder.addCase(viewOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default OrderSlice.reducer;
