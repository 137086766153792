import React, { useEffect } from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { listPage } from "../../../redux/feature/pageSlice";
import moment from "moment";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const Index = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { page, loading, isSuccessful } = useSelector((state) => state.page);

  useEffect(() => {
    dispatch(listPage({token}));
  }, [dispatch, token, isSuccessful]);

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    serverSide: true,
  };
  const columns = [
    {
      label: "ID",
      name: "id",
      
    },
    {
      label: "Title",
      name: "title",
    },
    {
      label: "Created At",
      name: "createdAt",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const result =
    page &&
    page?.map((val, index) => ({
      id: index + 1,
      title: val?.slug,
      createdAt: moment(val?.createdAt).format("LL"),
      action: (
        <Typography color="textSecondary" variant="h6">
          <Link to={`/admin/page/update/${val?._id}`}>
            <Button component="a" variant="outlined" color="secondary">
              Update
            </Button>
          </Link>
        </Typography>
      ),
    }));

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Pages"} link="/admin/Coupon/add" />
        {loading ? (
          <Circles
            height="80"
            width="80"
            color="#6f42c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loader_cl"
            visible={true}
          />
        ) : (
          <>
            <MUIDataTable data={result} columns={columns} options={options} />
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
