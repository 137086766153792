import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { viewUser } from "../../../redux/feature/userSlice";
import BaseCard from "../../components/baseCard";
import { viewCoupon } from "../../../redux/feature/CouponSlice";

const View = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { singleCoupon } = useSelector((state) => state.coupon);

  useEffect(() => {
    dispatch(viewCoupon({ id, token }));
  }, [dispatch , id , token]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Coupon/view"} link="/admin/coupon" name="Back" />
          <Card className="mt-5">
            <CardContent>
              <div className="row w-100 gx-4 gy-5">
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                  Title:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleCoupon?.title}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                  Rewards Code:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                     {singleCoupon?.code}
                  </Typography>
                </div>
                {/* <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                  Validity:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleCoupon?.validity}
                  </Typography>
                </div> */}
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                  Rewards Points:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleCoupon?.points}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                  Discount:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleCoupon?.discount || 0}%
                  </Typography>
                </div>

                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Status:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleCoupon?.status == 1 ? (
                      <Button variant="contained" color="success">
                        Active
                      </Button>
                    ) : (
                      <Button variant="contained" color="warning">
                        InActive
                      </Button>
                    )}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Created At:
                  </Typography>
                  <Typography variant="body2">
                    {moment(singleCoupon?.createdAt).format("LL")}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default View;
