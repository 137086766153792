import { baseUrl } from "./config";

//Constant
export const TYPE_USER = 1
export const TYPE_VENDOR = 2

export const API_LOGIN = baseUrl + "/login";
export const API_LOGOUT = baseUrl + "/logout";
export const API_GET_USER = baseUrl + "/user";
export const API_GET_USER_STATUS = baseUrl + "/user/status";
export const API_DELETE_USER = baseUrl + "/user/delete";
export const API_GET_USER_VIEW = baseUrl + "/user/view";
export const API_DASHBOARD_INFO = baseUrl + "/dashboardInfo";

export const API_CHANGE_PASSWORD = baseUrl + "/change-password";
export const API_PROFILE_EDIT = baseUrl + "/profile-edit";
export const API_SHOP_DETAILS = baseUrl + "/getShopDetails";

export const API_ANALYTICS_GRAPH = baseUrl + "/analytics/analyticsUser";
export const API_ANALYTICS_DASH = baseUrl + "/analytics/dashboardInfo";

// Banner
export const API_BANNER_ADD = baseUrl + "/banner/add";
export const API_BANNER_LIST = baseUrl + "/banner/list";
export const API_BANNER_STATUS = baseUrl + "/banner/status";
export const API_BANNER_DELETE = baseUrl + "/banner/delete";


export const API_CATEGORY_ADD = baseUrl + "/category/add";
export const API_CATEGORY_UPDATE = baseUrl + "/category/update";
export const API_CATEGORY_LIST = baseUrl + "/category/list";
export const API_CATEGORY_VIEW = baseUrl + "/category/view";
export const API_CATEGORY_STATUS = baseUrl + "/category/status";
export const API_CATEGORY_DELETE = baseUrl + "/category/delete";

export const API_TABLE_ADD = baseUrl + "/table/add";
export const API_TABLE_LIST = baseUrl + "/table/lists";
export const API_TABLE_LIST_ADMIN = baseUrl + "/table/all";
export const API_TABLE_STATUS = baseUrl + "/table/status";
export const API_TABLE_DELETE = baseUrl + "/table/delete";

//Coupon
export const API_COUPON_ADD = baseUrl + "/coupon/add";
export const API_COUPON_UPDATE = baseUrl + "/coupon/update";
export const API_COUPON_LIST = baseUrl + "/coupon/list";
export const API_COUPON_VIEW = baseUrl + "/coupon/view";
export const API_COUPON_STATUS = baseUrl + "/coupon/status";
export const API_COUPON_DELETE = baseUrl + "/coupon/delete";


//FAQ
export const API_FAQ_ADD = baseUrl + "/faq/add";
export const API_FAQ_UPDATE = baseUrl + "/faq/update";
export const API_FAQ_LIST = baseUrl + "/faq/list";
export const API_FAQ_VIEW = baseUrl + "/faq/view";
export const API_FAQ_STATUS = baseUrl + "/faq/status";
export const API_FAQ_DELETE = baseUrl + "/faq/delete";

//Feed
export const API_FEED_ADD = baseUrl + "/feed/add";
export const API_FEED_UPDATE = baseUrl + "/feed/update";
export const API_FEED_LIST = baseUrl + "/feed/list";
export const API_FEED_VIEW = baseUrl + "/feed/view";
export const API_FEED_STATUS = baseUrl + "/feed/status";
export const API_FEED_DELETE = baseUrl + "/feed/delete";
export const API_FEED_COMMENT_DELETE = baseUrl + "/feed/comment/delete";
export const API_FEED_LIKE_DELETE = baseUrl + "/feed/like/delete";

//Rating
export const API_RATING_ADD = baseUrl + "/rating/add";
export const API_RATING_UPDATE = baseUrl + "/rating/update";
export const API_RATING_LIST = baseUrl + "/rating/list";
export const API_RATING_VIEW = baseUrl + "/rating/view";
export const API_RATING_STATUS = baseUrl + "/rating/status";
export const API_RATING_DELETE = baseUrl + "/rating/delete";

//Order
export const API_ORDER_LIST = baseUrl + "/order/list";
export const API_ORDER_VIEW = baseUrl + "/order/view";
export const API_ORDER_DELETE = baseUrl + "/order/delete";

export const API_TRANSACTION_LIST = baseUrl + "/transaction/list";

//Report
export const API_REPORT_LIST = baseUrl + "/report/list";
export const API_REPORT_VIEW = baseUrl + "/report/view";
export const API_REPORT_DELETE = baseUrl + "/report/delete";

//Contact
export const API_CONTACT_LIST = baseUrl + "/contact/list";
export const API_CONTACT_VIEW= baseUrl + "/contact/view";
export const API_CONTACT_DELETE = baseUrl + "/contact/delete";

//Page
export const API_PAGE_LIST = baseUrl + "/page/list";
export const API_PAGE_UPDATE = baseUrl + "/page/update";
export const API_PAGE_SLUG = baseUrl + "/page/view";

export const API_MENU_ADD = baseUrl + "/menu/add";
export const API_MENU_UPDATE = baseUrl + "/menu/update";
export const API_MENU_LIST = baseUrl + "/menu/list";
export const API_MENU_VIEW = baseUrl + "/menu/view";
export const API_MENU_STATUS = baseUrl + "/menu/status";
export const API_MENU_DELETE = baseUrl + "/menu/delete";
export const API_FILE_DELETE = baseUrl + "/file/delete";

export const API_DOWNLOAD_QR = baseUrl + "/downloadQr";


// Restaurant
export const API_RESTAURANT_ADD = baseUrl + "/restaurant/add";
export const API_RESTAURANT_ADD_STAFF = "/restaurant/add-staff";
export const API_RESTAURANT_ANALYTICS_GRAPH = baseUrl + "/restaurant/analyticsUser";
export const API_RESTAURANT_ANALYTICS_DASH = baseUrl + "/restaurant/dashboardInfo";


// Bank
export const API_BANK_LIST = baseUrl + "/bank/list";
export const API_BANK_ADD = baseUrl + "/bank/add";
export const API_BANK_DELETE = baseUrl + "/bank/delete";

//Withdraw
export const API_WITHDRAW_LIST = baseUrl + "/withdraw/list";
export const API_WITHDRAW_ADD = baseUrl + "/withdraw/add";
export const API_WITHDRAW_UPDATE = baseUrl + "/withdraw/update";
export const API_WITHDRAW_DELETE = baseUrl + "/withdraw/delete";
export const API_WITHDRAW_VIEW = baseUrl + "/withdraw/view";










