import React, { useEffect } from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BaseCard from "../../components/baseCard";
import { viewMenu } from "../../../redux/feature/menuSlice";
import { baseUrlImage } from "../../../utils/config";
import { viewOrder } from "../../../redux/feature/orderSlice";

const View = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { singleOrder } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(viewOrder({ id, token }));
  }, [dispatch, id, token]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Order/view"} link="/admin/order" name="Back" />
          <Card className="mt-5">
            <CardContent>
              <div className="row w-100 gx-4 gy-5">
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Restaurant:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleOrder?.vendor_id?.name}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    User Name:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleOrder?.user_id?.name}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Table No.:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleOrder?.table}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Total Price:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleOrder?.price}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Status:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                  {singleOrder?.status === 2 ? (
                    <Button className="btn bg-gradient-primary">Delivery</Button>
                  ) : singleOrder?.status === 3 ? (
                    <Button className="btn bg-gradient-danger">Canceled</Button>
                  ) : singleOrder?.status === 1 ? (
                    <Button className="btn bg-gradient-danger">Preparing</Button>
                  ) : (
                    <Button className="btn bg-gradient-danger">Requested</Button>
                  )}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Payment Status:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleOrder?.payment_type === 1 ? (
                      <Button variant="contained" color="success">
                        Paid
                      </Button>
                    ) : (
                      <Button variant="contained" color="warning">
                        Not Paid
                      </Button>
                    )}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Order Type:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleOrder?.orderType === 1 ? (
                      <Button variant="contained" color="success">
                        Dining
                      </Button>
                    ) : (
                      <Button variant="contained" color="warning">
                        Take Way
                      </Button>
                    )}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Created At:
                  </Typography>
                  <Typography variant="body2">
                    {moment(singleOrder?.createdAt).format("LL")}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="mt-5">
            <CardContent>
              <Typography className="w-100 mb-1" variant="body1">
                Menu :
              </Typography>
              <div className="container">
                <div className="row">
                  {singleOrder?.product?.map((val, i) => (
                    <div className="col-md-2" key={i}>
                      <img
                        className="rounded img-fluid"
                        src={baseUrlImage + "/" + val?.image}
                        alt=""
                        style={{ height: "50%" }}
                      />
                      <h5 >
                        {val?.name}
                      </h5>
                      <p>(Qty:{val?.quantity || 1})</p>{" "}
                    </div>
                  ))}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default View;
