import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_BANNER_ADD,
  API_BANNER_DELETE,
  API_BANNER_LIST,
  API_BANNER_STATUS,
} from "../../utils/APIConstant";
import toast from "react-hot-toast";

export const getBanner = createAsyncThunk(
  "get-banner",
  async ({ token }) => {
    const res = await axios.request({
      method: "get",
      url: API_BANNER_LIST,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);


export const AddBanner = createAsyncThunk(
  "add-banner",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_BANNER_ADD,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);


export const statusBanner = createAsyncThunk(
  "status-banner",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_BANNER_STATUS,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const deleteBanner = createAsyncThunk(
  "delete-banner",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_BANNER_DELETE + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

const BannerSlice = createSlice({
  name: "banner",
  initialState: {
    loading: false,
    error: null,
    banner: [],
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBanner.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBanner.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.banner = action.payload.data;
    });
    builder.addCase(getBanner.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(AddBanner.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddBanner.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(AddBanner.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(statusBanner.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusBanner.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(statusBanner.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteBanner.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBanner.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteBanner.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default BannerSlice.reducer;
