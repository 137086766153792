import React from "react";
import { Toaster } from "react-hot-toast";
import Routers from "./routes";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ReduxProvider from "./redux/store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ReduxProvider>
            <Routers />
          </ReduxProvider>
        </LocalizationProvider>
        <Toaster position="top-center" />
      </BrowserRouter>
    </>
  );
}

export default App;
