import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_CATEGORY_ADD,
  API_CATEGORY_DELETE,
  API_CATEGORY_LIST,
  API_CATEGORY_STATUS,
  API_CATEGORY_UPDATE,
  API_CATEGORY_VIEW,
} from "../../utils/APIConstant";
import toast from "react-hot-toast";

export const getCategory = createAsyncThunk(
  "get-category",
  async ({ token }) => {
    const res = await axios.request({
      method: "get",
      url: API_CATEGORY_LIST,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const viewCategory = createAsyncThunk(
  "view-category",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "get",
      url: API_CATEGORY_VIEW + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const AddCategory = createAsyncThunk(
  "add-category",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_CATEGORY_ADD,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const UpdateCategory = createAsyncThunk(
  "update-category",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "PUT",
      url: API_CATEGORY_UPDATE,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const statusCategory = createAsyncThunk(
  "status-category",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_CATEGORY_STATUS,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const deleteCategory = createAsyncThunk(
  "delete-category",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_CATEGORY_DELETE + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

const CategorySlice = createSlice({
  name: "category",
  initialState: {
    loading: false,
    error: null,
    category: [],
    singleCategory: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.category = action.payload.data;
    });
    builder.addCase(getCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(AddCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(AddCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(viewCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
      state.singleCategory = action.payload.data;
    });
    builder.addCase(viewCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(statusCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(statusCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default CategorySlice.reducer;
