import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_FAQ_ADD,
  API_FAQ_DELETE,
  API_FAQ_LIST,
  API_FAQ_STATUS,
  API_FAQ_UPDATE,
  API_FAQ_VIEW,
} from "../../utils/APIConstant";
import toast from "react-hot-toast";

export const getFaq = createAsyncThunk("get-faq", async (token) => {
  const res = await axios.request({
    method: "get",
    url: API_FAQ_LIST,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const viewFaq = createAsyncThunk(
  "view-faq",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "get",
      url: API_FAQ_VIEW + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const AddFaq = createAsyncThunk(
  "add-faq",
  async ({ values, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_FAQ_ADD,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: values,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const UpdateFaq = createAsyncThunk(
  "update-faq",
  async ({ values, token }) => {
    const res = await axios.request({
      method: "PUT",
      url: API_FAQ_UPDATE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: values,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const statusFaq = createAsyncThunk(
  "status-faq",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_FAQ_STATUS,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const deleteFaq = createAsyncThunk(
  "delete-faq",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_FAQ_DELETE + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

const FaqSlice = createSlice({
  name: "faq",
  initialState: {
    loading: false,
    error: null,
    faq: [],
    singleFaq: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFaq.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFaq.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.faq = action.payload.data;
    });
    builder.addCase(getFaq.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(AddFaq.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddFaq.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(AddFaq.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });


    builder.addCase(viewFaq.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewFaq.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
      state.singleFaq = action.payload.data;
    });
    builder.addCase(viewFaq.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(statusFaq.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusFaq.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(statusFaq.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteFaq.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteFaq.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteFaq.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default FaqSlice.reducer;
