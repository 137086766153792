import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import BaseCard from "../../components/baseCard";
import { baseUrlImage } from "../../../utils/config";
import { getShop } from "../../../redux/feature/authSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const [shop, setShop] = useState(null);
  const { auth , token } = useSelector((state) => state.auth);

  
  useEffect(() => {
    dispatch(getShop(token)).then((value) => {
      setShop(value?.payload?.data);
    });
  }, [dispatch, token]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Restaurant Profile"} />
          <div class="row justify-content-center">
            <div class="card">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <div className="card card-body border-0 shadow-sm rounded-4">
                    <Avatar
                      className="shadow-sm mx-auto"
                      alt="User Avatar"
                      src={
                        auth?.image !== ""
                          ? baseUrlImage + "" + auth?.image
                          : "/assets/images/faces/face1.jpg"
                      }
                      sx={{ width: 150, height: 150, mb: 2 }}
                    />
                  </div>
                  <div className="card card-body border-0 shadow-sm rounded-4 mt-1">
                    <Avatar
                      className="shadow-sm mx-auto"
                      alt="User Avatar"
                      src={
                        shop?.shop_image ? baseUrlImage + "" + shop?.shop_image : "/assets/images/faces/face1.jpg"
                      }
                      sx={{ width: 150, height: 150, mb: 2 }}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="card card-body border-0 shadow-sm rounded-4 h-100">
                    <Box className="row w-100 gy-4">
                      <Typography variant="h4" className="col-12 mb-2 mt-2">
                        {auth?.name}
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-4">
                        Email:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-4">
                        {auth?.email}
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-2">
                        Phone:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-2">
                        {auth?.phone}
                      </Typography>
                      <Typography variant="h6" className="col-12 mb-2 mt-4">
                        Shop Details:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-2">
                        Shop Name:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-2">
                        {shop?.shop_name}
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-2">
                        Shop address:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-2">
                        {shop?.address}
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-2">
                        Shop City:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-2">
                        {shop?.city}
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-2">
                        Shop Description:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-2">
                        {shop?.description}
                      </Typography>
                      <Typography variant="body1"> </Typography>
                      {/* Add additional user details here */}
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
