import React, { useEffect } from "react";
import BaseCard from "../../components/baseCard";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBank } from "../../../redux/feature/bankSlice";
import { AddWithdraw } from "../../../redux/feature/withdrawSlice";

const validationSchema = yup.object().shape({
  bank_id: yup
    .string("Select Bank name")
    .required("Bank name is a required field"),
  amount: yup
    .number()
    .required()
    .positive()
    .integer()
    .test(
      "Must be exactly 5 digit",
      (val) => val && val.toString().length >= 1 && val.toString().length <= 6
    ),
});

const Add = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { bank } = useSelector((state) => state.bank);

  useEffect(() => {
    dispatch(getBank(token));
  }, [dispatch, token]);

  const formik = useFormik({
    initialValues: {
      bank_id: "",
      amount: "",
      remark: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(AddWithdraw({ values, token })).then((value) => {
        if (value.payload.success) {
          navigate(-1);
        }
      });
    },
  });

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Withdrawal / Add"} />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="exampleInputUsername1">Bank Name</label>
                    <FormGroup
                      controlId="bank_id"
                      className="position-relative"
                    >
                      <FormControl
                        as="select"
                        name="bank_id"
                        value={formik.values.bank_id}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.bank_id &&
                          Boolean(formik.errors.bank_id)
                        }
                      >
                        <option>Select</option>
                        {bank &&
                          bank?.map((val, index) => (
                            <option value={val?._id} key={index}>
                              {val?.bankName}
                            </option>
                          ))}
                      </FormControl>
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.bank_id && formik.errors.bank_id}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <div class="form-group form-group col-md-6">
                    <label for="amount">Amount</label>
                    <FormGroup controlId="amount" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter Amount"
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.amount && Boolean(formik.errors.amount)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.amount && formik.errors.amount}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <div class="form-group form-group">
                    <label for="remark">Remark</label>
                    <FormGroup controlId="remark" className="position-relative">
                      <FormControl
                        type={"text"}
                        row={4}
                        className="form-control"
                        placeholder="Enter Remark"
                        value={formik.values.remark}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.remark && Boolean(formik.errors.remark)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.remark && formik.errors.remark}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                </div>
                <button
                  type="submit"
                  class="btn btn-gradient-primary me-2 mt-5"
                >
                  Submit
                </button>
                <Link to="/restaurant/withdraw" class="btn btn-light mt-5">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
