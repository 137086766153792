import React, { useEffect, useState } from "react";
import BaseCard from "../../components/baseCard";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddFaq } from "../../../redux/feature/FaqSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { EditorModule } from "../../../utils/editor";

const validationSchema = yup.object().shape({
  question: yup.string("Enter your question").required(),
  answer: yup.string("Enter your answer").required(),
  status: yup.string("Select status").required("Status is a required field"),
});

const Add = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
      status: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(AddFaq({ values, token }));
      navigate(-1);
    },
  });

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Faq / Add"} />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                   <div class="form-group form-group">
                    <label for="question">Question</label>
                    <FormGroup controlId="question" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter question"
                        value={formik.values.question}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.question && Boolean(formik.errors.question)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.question && formik.errors.question}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div> 

                  {/* <div class="form-group form-group">
                    <ReactQuill
                      modules={EditorModule}
                      theme="snow"
                      value={formik.values.question}
                      onChange={formik.handleChange}
                      placeholder="Content goes here..."
                    />
                  </div>

                  <div class="form-group form-group">
                    <ReactQuill
                      modules={EditorModule}
                      theme="snow"
                      value={formik.values.answer}
                      onChange={formik.handleChange}
                      placeholder="Content goes here..."
                    />
                  </div> */}

                   <div class="form-group form-group">
                    <label for="answer">Answer</label>
                    <FormGroup controlId="answer" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter answer"
                        value={formik.values.answer}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.answer && Boolean(formik.errors.answer)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.answer && formik.errors.answer}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="exampleInputUsername1">Status</label>
                    <FormGroup controlId="status" className="position-relative">
                      <FormControl
                        as="select"
                        name="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.status && Boolean(formik.errors.status)
                        }
                      >
                        <option>Select</option>
                        <option value="0">Inactive</option>
                        <option value="1">Active</option>
                      </FormControl>
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.status && formik.errors.status}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                </div>
                <button type="submit" class="btn btn-gradient-primary me-2">
                  Submit
                </button>
                <Link to="/admin/faq" class="btn btn-light">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
