import React, { useState } from "react";
import BaseCard from "../../components/baseCard";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Card, Button, CardMedia, Grid } from "@mui/material";
import { AddBanner } from "../../../redux/feature/BannerSlice";

const Add = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const { token } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      image: "",
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      const myForm = new FormData();
      myForm.set("image", values.image);
      dispatch(AddBanner({ myForm, token }));
      navigate(-1);
    },
  });

  const handleCoverImageChange = (e) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      setFile(e.target.result);
    };
    formik.setFieldValue("image", e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Banner / Add"} />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Box display="flex" flexWrap="wrap">
                        {file && (
                          <Card
                            sx={{
                              maxWidth: 200,
                              maxHeight: 200,
                              margin: "8px",
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="140"
                              image={file}
                              alt={`Product Cover`}
                            />
                          </Card>
                        )}
                      </Box>
                    </Box>

                    <Box>
                      <input
                        type="file"
                        onChange={handleCoverImageChange}
                        accept="image/*"
                        style={{ display: "none" }}
                        id="cover-image-upload"
                      />
                      <label htmlFor="cover-image-upload">
                        <Button
                          variant="contained"
                          component="span"
                          color="secondary"
                        >
                          Image
                        </Button>
                      </label>
                    </Box>
                  </Grid>
                </div>
                <button type="submit" class="btn btn-gradient-primary mt-4">
                  Submit
                </button>{" "}
                <Link to="/admin/banner" class="btn btn-light mt-4">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
