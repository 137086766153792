import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_FILE_DELETE,
  API_MENU_ADD,
  API_MENU_DELETE,
  API_MENU_LIST,
  API_MENU_STATUS,
  API_MENU_UPDATE,
  API_MENU_VIEW,
} from "../../utils/APIConstant";
import toast from "react-hot-toast";

export const getMenu = createAsyncThunk("get-menus", async ({ type, token }) => {
  const res = await axios.request({
    method: "get",
    url: API_MENU_LIST,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const AddMenu = createAsyncThunk(
  "add-menu",
  async ({ myForm, token }) => {
    try {
      const res = await axios.request({
        method: "POST",
        url: API_MENU_ADD,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        data: myForm,
      });
      toast.success(res.data.message);
      return res.data;
    } catch (err) {
      toast.error(err.response.data.error);
      return err.response.data;
    }
  }
);

export const viewMenu = createAsyncThunk("view-menu", async ({ id, token }) => {
  const res = await axios.request({
    method: "get",
    url: API_MENU_VIEW + "/" + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const statusMenu = createAsyncThunk(
  "status-menu",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_MENU_STATUS,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const UpdateMenu = createAsyncThunk(
  "update-menu",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "PUT",
      url: API_MENU_UPDATE,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const deleteFile = createAsyncThunk(
  "delete-file",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "post",
      url: API_FILE_DELETE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const deleteMenu = createAsyncThunk(
  "delete-menu",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_MENU_DELETE + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

const MenuSlice = createSlice({
  name: "menu",
  initialState: {
    loading: false,
    error: null,
    menu: [],
    singleMenu: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMenu.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMenu.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.menu = action.payload.data;
    });
    builder.addCase(getMenu.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(AddMenu.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddMenu.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(AddMenu.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(viewMenu.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewMenu.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.singleMenu = action.payload.data;
    });
    builder.addCase(viewMenu.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(statusMenu.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusMenu.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(statusMenu.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(UpdateMenu.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(UpdateMenu.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(UpdateMenu.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(deleteMenu.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteMenu.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteMenu.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(deleteFile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteFile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default MenuSlice.reducer;
