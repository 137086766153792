import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_TABLE_ADD,
  API_TABLE_DELETE,
  API_TABLE_LIST,
  API_TABLE_LIST_ADMIN,
  API_TABLE_STATUS,
} from "../../utils/APIConstant";
import toast from "react-hot-toast";

export const getTable = createAsyncThunk("get-tables", async (token) => {
  const res = await axios.request({
    method: "get",
    url: API_TABLE_LIST,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const getTableAll = createAsyncThunk(
  "get-table-all",
  async ({ token, id }) => {
    const res = await axios.request({
      method: "get",
      url: API_TABLE_LIST_ADMIN + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const AddTable = createAsyncThunk(
  "add-table",
  async ({ values, token }) => {
    try {
      const res = await axios.request({
        method: "Post",
        url: API_TABLE_ADD,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: values,
      });
      toast.success(res.data.message);
      return res.data;
    } catch (err) {
      toast.error(err.response.data.error);
      return err.response.data;
    }
  }
);

export const statusTable = createAsyncThunk(
  "status-table",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_TABLE_STATUS,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const deleteTable = createAsyncThunk(
  "delete-table",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_TABLE_DELETE + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

const TableSlice = createSlice({
  name: "table",
  initialState: {
    loading: false,
    error: null,
    table: [],
    // singleTable: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTable.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTable.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.table = action.payload.data;
    });
    builder.addCase(getTable.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(AddTable.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddTable.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(AddTable.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(statusTable.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusTable.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(statusTable.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteTable.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTable.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteTable.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default TableSlice.reducer;
