import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { FormControl, FormGroup, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import { Login } from "../../redux/feature/authSlice";
import toast from "react-hot-toast";

const validationSchema = yup.object().shape({
  email: yup
    .string("Enter your email or username")
    .required("Email or username is required"),
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
});

function SignIn() {
  const dispatch = useDispatch();
  const { auth, isAuthenticated } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(Login(values));
    },
  });

  if (isAuthenticated) {
    if (auth?.role === 0) {
      return <Navigate to={"/admin/dashboard"} />;
    } else if (auth?.role === 2) {
      return <Navigate to={"/restaurant/dashboard"} />;
    } else {
      toast.error("Sorry, You Are Not Allowed to Access This Page");
    }
  }
  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-5">
                  <div className="brand-logo">
                    <img
                      src="./assets/images/logo1.png"
                      alt=""
                      className="mx-auto d-block"
                    />
                  </div>
                  <h4>Hello! let's get started</h4>
                  <h6 className="font-weight-light">Sign in to continue.</h6>
                  <form onSubmit={formik.handleSubmit} className="mt-3 mt-md-5">
                    <div className="form-group">
                      <FormGroup
                        controlId="email"
                        className="position-relative"
                      >
                        <FormControl
                          type={"text"}
                          className="form-control"
                          placeholder="Enter email"
                          size="lg"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          isInvalid={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {formik.touched.email && formik.errors.email}
                        </Form.Control.Feedback>
                      </FormGroup>
                    </div>
                    <div className="form-group">
                      <FormGroup
                        controlId="password"
                        className="position-relative"
                      >
                        <FormControl
                          type={"password"}
                          className="form-control"
                          placeholder="Enter password"
                          size="lg"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          isInvalid={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {formik.touched.password && formik.errors.password}
                        </Form.Control.Feedback>
                      </FormGroup>
                    </div>
                    <div className="mt-3 text-center">
                      <Button variant="primary" type="submit">
                        SIGN IN
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {isAuthenticated && <Navigate to="/admin/dashboard" replace={true} />}
          {/* <!-- content-wrapper ends --> */}
        </div>
        {/* <!-- page-body-wrapper ends --> */}
      </div>
    </>
  );
}

export default SignIn;
