import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_PAGE_LIST,
  API_PAGE_SLUG,
  API_PAGE_UPDATE,
} from "../../utils/APIConstant";
import toast from "react-hot-toast";

export const updatePage = createAsyncThunk(
  "update/page",
  async ({ values, token }) => {
    try {
      const res = await axios.request({
        method: "PUT",
        url: API_PAGE_UPDATE,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: values,
      });
      toast.success(res.data.message);
      return res.data;
    } catch (err) {
      toast.error(err.response.data.error);
      return err.response.data;
    }
  }
);

export const listPage = createAsyncThunk("list/page", async ({ token }) => {
  try {
    const res = await axios.request({
      method: "GET",
      url: API_PAGE_LIST,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    toast.error(err.response.data.error);
    return err.response.data;
  }
});

export const getPage = createAsyncThunk("get/page", async ({ id, token }) => {
  try {
    const res = await axios.request({
      method: "GET",
      url: API_PAGE_SLUG + "/" + id,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    toast.error(err.response.data.error);
    return err.response.data;
  }
});

const PageSlice = createSlice({
  name: "page",
  initialState: {
    loading: false,
    error: null,
    page: [],
    singlePage: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listPage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listPage.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.page = action.payload.data;
    });
    builder.addCase(listPage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getPage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPage.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
      state.singlePage = action.payload.data;
    });
    builder.addCase(getPage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(updatePage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePage.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(updatePage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default PageSlice.reducer;
