import React, { useEffect, useState } from "react";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import BaseCard from "../../components/baseCard";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateFaq, viewFaq } from "../../../redux/feature/FaqSlice";

const validationSchema = yup.object().shape({
  question: yup.string("Enter your question").required(),
  answer: yup.string("Enter your answer").required(),
});

const Update = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { token } = useSelector((state) => state.auth);
  const { singleFaq } = useSelector((state) => state.faq);

  useEffect(() => {
    dispatch(viewFaq({ id, token }));
  }, [dispatch, token, id]);

  const formik = useFormik({
    initialValues: {
      question: singleFaq?.question || "",
      answer: singleFaq?.answer || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.id = singleFaq._id
      dispatch(UpdateFaq({ values, token }));
      navigate(-1);
    },
  });

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard
          title={"Faq / Update"}
          link="/admin/faq"
          name="Back"
        />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
            <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group form-group col-md-6">
                    <label for="question">Question</label>
                    <FormGroup controlId="question" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter question"
                        value={formik.values.question}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.question && Boolean(formik.errors.question)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.question && formik.errors.question}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <div class="form-group form-group col-md-6">
                    <label for="answer">Answer</label>
                    <FormGroup controlId="answer" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter answer"
                        value={formik.values.answer}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.answer && Boolean(formik.errors.answer)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.answer && formik.errors.answer}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                </div>
                <button type="submit" class="btn btn-gradient-primary me-2">
                  Submit
                </button>
                <Link to="/admin/category" class="btn btn-light">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
