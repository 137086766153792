import React, { useEffect, useState } from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import { baseUrlImage } from "../../../utils/config";
import fileDownload from "js-file-download";
import axios from "axios";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { getWithdraw } from "../../../redux/feature/withdrawSlice";
import { API_DOWNLOAD_QR } from "../../../utils/APIConstant";
import Add from "./Add";

const Index = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [Id, setData] = useState();
  const handleClose = () => setShow(false);

  const { token } = useSelector((state) => state.auth);
  const { withdraw, loading, isSuccessful } = useSelector(
    (state) => state.withdraw
  );

  useEffect(() => {
    dispatch(getWithdraw(token));
    setShow(false);
  }, [dispatch, token, isSuccessful]);

  const HandleModal = async (id) => {
    setShow(true);
    setData(id);
  };

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    serverSide: true,
  };

  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Vendor Name",
      name: "vendor",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Amount",
      name: "amount",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Paid Slip",
      name: "image",
    },
    {
      label: "Transaction Id",
      name: "transaction",
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "CreatedAt",
      name: "createdAt",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const downloadFile = async (name) => {
    await axios
      .get(API_DOWNLOAD_QR + "/" + name, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        fileDownload(res.data, "qr_code.jpeg");
      });
  };

  const result =
    withdraw &&
    withdraw?.map((val, index) => ({
      id: index + 1,
      amount: val?.amount,
      transaction: val?.transaction_id,
      bankName: val?.bank_id?.bankName,
      vendor: (
        <Link to={`/admin/shopkeeper/view/${val?.vendor_id?._id}`}>
          {val?.vendor_id?.name}
        </Link>
      ),
      image: (
        <>
          {val?.image && (
            <>
              <Avatar
                src={baseUrlImage + "/" + val?.image}
                sx={{ width: 60, height: 60 }}
              />
              <button
                onClick={() => downloadFile(val?.image)}
                className="btn btn-success mt-1 ml-3"
              >
                {" "}
                <ArrowCircleDownIcon />
              </button>
            </>
          )}
        </>
      ),
      status: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {val?.status === 1 ? (
            <Button className="btn bg-gradient-primary">Paid</Button>
          ) : val?.status === 2 ? (
            <Button className="btn bg-gradient-danger">Rejected</Button>
          ) : (
            <Button className="btn bg-gradient-danger">Pending</Button>
          )}
        </Box>
      ),
      createdAt: moment(val?.createdAt).format("LL"),
      action: (
        <Typography color="textSecondary" variant="h6">
          {val?.status === 0 && (
            <Button
              component="a"
              variant="outlined"
              color="primary"
              onClick={() => HandleModal(val?._id)}
            >
              Accept
            </Button>
          )}{" "}
          <Link to={`/admin/withdraw/view/${val?._id}`}>
            <Button component="a" variant="outlined" color="secondary">
              View
            </Button>
          </Link>{" "}
        </Typography>
      ),
    }));

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Withdrawal"} />
          {loading ? (
            <Circles
              height="80"
              width="80"
              color="#6f42c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass="loader_cl"
              visible={true}
            />
          ) : (
            <>
              <MUIDataTable data={result} columns={columns} options={options} />
            </>
          )}
        </div>
      </div>
      <Add show={show} onClose={handleClose} Id={Id} />
    </>
  );
};

export default Index;
