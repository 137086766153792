import React, { useEffect } from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BaseCard from "../../components/baseCard";
import { viewContact } from "../../../redux/feature/ContactSlice";

const View = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { singleContact } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(viewContact({ id, token }));
  }, [dispatch, id, token]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Contact/view"} link="/admin/contact" name="Back" />
          <Card className="mt-5">
            <CardContent>
              <div className="row w-100 gx-4 gy-5">
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Full name:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleContact?.name}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Email:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleContact?.email}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Created At:
                  </Typography>
                  <Typography variant="body2">
                    {moment(singleContact?.createdAt).format("LL")}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="mt-5">
            <CardContent>
              <div className="col-md-4">
                <Typography className="w-100 mb-1" variant="body1">
                  Description:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {singleContact?.description}
                </Typography>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default View;
