import React, { useEffect } from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Typography, Button } from "@mui/material";
import moment from "moment";
import { deleteRating, getRating } from "../../../redux/feature/RatingSlice";
import { Avatar } from "@mui/material";
import { baseUrlImage } from "../../../utils/config";

const Rating = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { rating, loading, isSuccessful } = useSelector(
    (state) => state.rating
  );

  useEffect(() => {
    dispatch(getRating({token}));
  }, [dispatch, token, isSuccessful]);

  const deleteUser = (id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteRating({ id: id, token }));
      }
    });
  };

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
    // rowsPerPage: rowsPerPage,
    // rowsPerPageOptions: [10, 25, 50, 100],
    // serverSide: true,
    // page: page,
    // count: classes?.totalData,
    // onSearchChange: (searchText) => handleSearch(searchText),
    // searchText: searchText,
    // onChangePage: handleChangePage,
    // onChangeRowsPerPage: handleChangeRowsPerPage,
  };
  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Product",
      name: "product",
    },
    {
      label: "Menu Image",
      name: "menu",
      
    },
    {
      label: "Given By",
      name: "user_id",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },

    {
      label: "Rating",
      name: "rating",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Review",
      name: "comment",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Created At",
      name: "createdAt",
    }
  ];

  const result =
    rating &&
    rating?.map((val, index) => ({
      id: index + 1,
      product: val?.product?.title,
      menu: (
        <Avatar
          src={baseUrlImage + "" + val?.files?.name}
          sx={{ width: 50, height: 50 }}
        />
      ),
      user_id: val?.user?.name,
      rating: val?.rating,
      comment: val?.comment,
      createdAt: moment(val?.createdAt).format("LL"),
      action: (
        <Typography color="textSecondary" variant="h6">
        </Typography>
      ),
    }));

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Review"}  />
        {loading ? (
          <Circles
            height="80"
            width="80"
            color="#6f42c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loader_cl"
            visible={true}
          />
        ) : (
          <>
            <MUIDataTable data={result} columns={columns} options={options} />
          </>
        )}
      </div>
    </div>
  );
};

export default Rating;
