import React, { useEffect } from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { Box } from "@mui/material";
import { baseUrlImage } from "../../../utils/config";
import { Avatar } from "@mui/material";
import axios from "axios";
import { API_DOWNLOAD_QR } from "../../../utils/APIConstant";
import fileDownload from "js-file-download";
import {
  deleteTable,
  getTable,
  statusTable,
} from "../../../redux/feature/tableSlice";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

const Index = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { table, loading, isSuccessful } = useSelector((state) => state.table);

  useEffect(() => {
    dispatch(getTable(token));
  }, [dispatch, token, isSuccessful]);

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    serverSide: true,
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteTable({ id: id, token }));
      }
    });
  };

  const handleToggle = (id, checked) => {
    let myForm = {
      id,
      status: checked === false ? 0 : 1,
    };
    dispatch(statusTable({ myForm, token }));
  };

  const downloadFile = async (name) => {
    await axios
      .get(API_DOWNLOAD_QR + "/" + name, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res)
        fileDownload(res.data, "qr_code.jpeg");
      });
  };

  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Table no.",
      name: "table",
    },
    {
      label: "Qr Code",
      name: "qr_code",
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "CreatedAt",
      name: "createdAt",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const result =
    table &&
    table?.map((val, index) => ({
      id: index + 1,
      table: val?.title,
      qr_code : (
        <>
          <Avatar
            src={baseUrlImage + "/qrCode/" + val?.qrCode}
            sx={{ width: 50, height: 50 }}
          />
            <button
              onClick={() => downloadFile(val?.qrCode)}
              className="btn btn-success d-block mt-1"
            >
              {" "}
              <ArrowCircleDownIcon />
            </button>
        </>
      ),
      status: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={val?.status == 0 ? false : true}
                onChange={(event) =>
                  handleToggle(val._id, event.target.checked)
                }
                color="secondary"
                name="toggleButton"
              />
            }
          />
        </Box>
      ),
      createdAt: moment(val?.createdAt).format("LL"),
      action: (
        <Typography color="textSecondary" variant="h6">
          <Button
            sx={{
              marginLeft: "5px",
            }}
            variant="outlined"
            color="error"
            onClick={() => deleteUser(val._id)}
          >
            Delete
          </Button>
        </Typography>
      ),
    }));

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Table`s"} link={"/restaurant/table/add"} />
        {loading ? (
          <Circles
            height="80"
            width="80"
            color="#6f42c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loader_cl"
            visible={true}
          />
        ) : (
          <>
            <MUIDataTable data={result} columns={columns} options={options} />
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
