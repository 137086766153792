import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import account from "./account";
import BaseCard from "../../components/baseCard";
import { baseUrlImage } from "../../../utils/config";

const Profile = () => {
  const { loading, auth, token } = useSelector((state) => state.auth);
  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Admin Profile"} />
          <div class="row justify-content-center">
            <div class="card">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <div className="card card-body border-0 shadow-sm rounded-4">
                    <Avatar
                      className="shadow-sm mx-auto"
                      alt="User Avatar"
                      src={auth?.image !== "" ? baseUrlImage +""+auth?.image : "/assets/images/faces/face1.jpg"}
                      sx={{ width: 200, height: 200, mb: 2 }}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="card card-body border-0 shadow-sm rounded-4 h-100">
                    <Box className="row w-100 gy-4">
                      <Typography variant="h4" className="col-12 mb-2 mt-2">
                        {auth?.name}
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-4">
                        Email:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-4">
                        {auth?.email}
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-2">
                        Phone:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-2">
                        {auth?.phone}
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-2">
                         Commission:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-2">
                        {auth?.commission}%
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-2">
                         Tax:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-2">
                        {auth?.tax}%
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-2">
                        Text Points:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-2">
                        {auth?.text_points}
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-2">
                         Image Points:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-2">
                        {auth?.image_points}
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-4 mt-2">
                         Video Points:
                      </Typography>
                      <Typography variant="body1" className="mb-1 col-8 mt-2">
                        {auth?.video_points}
                      </Typography>
                     
                      <Typography variant="body1"> </Typography>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;