import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { baseUrlImage } from "../../../utils/config";

const Sidenav = () => {
  const { auth } = useSelector((state) => state.auth);
  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a href="#" className="nav-link">
              <div className="nav-profile-image">
                <img
                  src={
                    auth?.image !== ""
                      ? baseUrlImage + "" + auth?.image
                      : "/assets/images/faces/face1.jpg"
                  }
                  alt="idmage"
                />
                <span className="login-status online"></span>
              </div>
              <div className="nav-profile-text d-flex flex-column">
                <span className="font-weight-bold mb-2">{auth?.name}</span>
                {/* <span className="text-secondary text-small">Restaurant Panel</span> */}
              </div>
              {/* <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i> */}
            </a>
          </li>
          <NavLink className="nav-item text-decoration-none" to="dashboard">
            <Link className="nav-link" to="/restaurant/dashboard">
              <span className="menu-title">Dashboard</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="staff">
            <Link className="nav-link" to="/restaurant/staff">
              <span className="menu-title">Manage Staff</span>
              <i className="mdi mdi-account-star menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="table">
            <Link className="nav-link" to="/restaurant/table">
              <span className="menu-title">Table</span>
              <i className="mdi mdi-account-star menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="menu">
            <Link className="nav-link" to="/restaurant/menu">
              <span className="menu-title">Menu</span>
              <i className="mdi mdi-menu menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="coupon">
            <Link className="nav-link" to="/restaurant/coupon">
              <span className="menu-title">Coupon</span>
              <i className="mdi mdi-vector-selection menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="order">
            <Link className="nav-link" to="/restaurant/order">
              <span className="menu-title">Orders</span>
              <i className="mdi mdi-weight menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="transaction">
            <Link className="nav-link" to="/restaurant/transaction">
              <span className="menu-title">Transactions</span>
              <i className="mdi mdi-credit-card-multiple menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="bank">
            <Link className="nav-link" to="/restaurant/bank">
              <span className="menu-title">Bank Details</span>
              <i className="mdi mdi-bank menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="withdraw">
            <Link className="nav-link" to="/restaurant/withdraw">
              <span className="menu-title">Withdrawal Request</span>
              <i className="mdi mdi-bank menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="feed">
            <Link className="nav-link" to="/restaurant/feed">
              <span className="menu-title">Feed</span>
              <i className="mdi mdi-cube menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="review">
            <Link className="nav-link" to="/restaurant/review">
              <span className="menu-title">Review</span>
              <i className="mdi mdi-star menu-icon"></i>
            </Link>
          </NavLink>
        </ul>
      </nav>
    </>
  );
};

export default Sidenav;
