import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BANK_LIST , API_BANK_ADD ,API_BANK_DELETE } from "../../utils/APIConstant";
import toast from "react-hot-toast";

export const getBank = createAsyncThunk("get-bank", async (token) => {
  const res = await axios.request({
    method: "get",
    url: API_BANK_LIST,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const AddBank = createAsyncThunk(
  "add-bank",
  async ({ values, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_BANK_ADD,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: values,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const deleteBank = createAsyncThunk(
  "delete-bank",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_BANK_DELETE + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(res.data.message);
    return res.data;
  }
);

const bankSlice = createSlice({
  name: "bank",
  initialState: {
    loading: false,
    error: null,
    bank: [],
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBank.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBank.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.bank = action.payload.data;
    });
    builder.addCase(getBank.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(AddBank.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddBank.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(AddBank.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteBank.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBank.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteBank.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default bankSlice.reducer;
