import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_RATING_ADD,
  API_RATING_DELETE,
  API_RATING_LIST,
  API_RATING_STATUS,
  API_RATING_UPDATE,
  API_RATING_VIEW,
} from "../../utils/APIConstant";
import toast from "react-hot-toast";

export const getRating = createAsyncThunk(
  "get-rating",
  async ({ token }) => {
    const res = await axios.request({
      method: "get",
      url: API_RATING_LIST,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const viewRating = createAsyncThunk(
  "view-rating",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "get",
      url: API_RATING_VIEW + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const AddRating = createAsyncThunk(
  "add-rating",
  async ({ values, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_RATING_ADD,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: values,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const UpdateRating = createAsyncThunk(
  "update-rating",
  async ({ values, token }) => {
    const res = await axios.request({
      method: "PUT",
      url: API_RATING_UPDATE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: values,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const statusRating = createAsyncThunk(
  "status-rating",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_RATING_STATUS,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const deleteRating = createAsyncThunk(
  "delete-rating",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_RATING_DELETE + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

const RatingSlice = createSlice({
  name: "rating",
  initialState: {
    loading: false,
    error: null,
    rating: [],
    singleRating: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRating.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRating.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.rating = action.payload.data;
    });
    builder.addCase(getRating.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(AddRating.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddRating.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(AddRating.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(viewRating.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewRating.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
      state.singleRating = action.payload.data;
    });
    builder.addCase(viewRating.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(statusRating.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusRating.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(statusRating.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteRating.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteRating.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteRating.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default RatingSlice.reducer;
