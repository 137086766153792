import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_WITHDRAW_LIST,
  API_WITHDRAW_ADD,
  API_WITHDRAW_DELETE,
  API_WITHDRAW_VIEW,
  API_WITHDRAW_UPDATE,
} from "../../utils/APIConstant";
import toast from "react-hot-toast";

export const getWithdraw = createAsyncThunk("get-withdraw", async (token) => {
  const res = await axios.request({
    method: "get",
    url: API_WITHDRAW_LIST,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const AddWithdraw = createAsyncThunk(
  "add-withdraw",
  async ({ values, token }) => {
    try {
      const res = await axios.request({
        method: "Post",
        url: API_WITHDRAW_ADD,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: values,
      });
      toast.success(res.data.message);
      return res.data;
    } catch (err) {
      toast.error(err.response.data.error);
      return err.response.data;
    }
  }
);

export const viewWithdraw = createAsyncThunk(
  "view-withdraw",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "get",
      url: API_WITHDRAW_VIEW + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const updateWithDraw = createAsyncThunk(
  "update-with",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_WITHDRAW_UPDATE,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const deleteWithdraw = createAsyncThunk(
  "delete-withdraw",
  async ({ id, token }) => {
    try {
      const res = await axios.request({
        method: "delete",
        url: API_WITHDRAW_DELETE + "/" + id,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(res.data.message);
      return res.data;
    } catch (err) {
      toast.error(err.response.data.error);
      return err.response.data;
    }
  }
);

const withdrawSlice = createSlice({
  name: "withdraw",
  initialState: {
    loading: false,
    error: null,
    withdraw: [],
    withdrawSingle: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWithdraw.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWithdraw.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.withdraw = action.payload.data;
    });
    builder.addCase(getWithdraw.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(viewWithdraw.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewWithdraw.fulfilled, (state, action) => {
      state.loading = false;
      state.withdrawSingle = action.payload.data;
    });
    builder.addCase(viewWithdraw.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(AddWithdraw.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddWithdraw.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(AddWithdraw.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteWithdraw.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteWithdraw.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteWithdraw.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default withdrawSlice.reducer;
