import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AdminLayouts from "../ProtectedRoute/AdminLayout";
import RestaurantLayout from "../ProtectedRoute/RestaurantLayout";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import { useSelector } from "react-redux";
import Error from "../pages/NotFound";
import SignIn from "../pages/auth/SignIn";
import Dashboard from "../admin/pages/dashboard";
import RestaurantDashboard from "../restaurants/pages/dashboard";
import Category from "../admin/pages/category";
import User from "../admin/pages/user";
import AddCategory from "../admin/pages/category/Add";
import Shopkeeper from "../admin/pages/shopkeeper";
import ShopkeeperAdd from "../admin/pages/shopkeeper/Add";
import CouponUpdate from "../admin/pages/coupon/update";
import Coupon from "../admin/pages/coupon";
import CouponAdd from "../admin/pages/coupon/Add";
import CouponView from "../admin/pages/coupon/view";
import ViewUser from "../admin/pages/user/view";
import ViewShopkeeper from "../admin/pages/shopkeeper/view";
import UpdateCategory from "../admin/pages/category/update";
import EditProfile from "../admin/pages/adminPage/editProfile";
import Profile from "../admin/pages/adminPage/profile";
import RestaurantProfile from "../restaurants/pages/VendorPage/profile";
import RestaurantEditProfile from "../restaurants/pages/VendorPage/editProfile";
import RestaurantChangePassword from "../restaurants/pages/VendorPage/changePassword";
import ChangePassword from "../admin/pages/adminPage/changePassword";
import Feed from "../admin/pages/Feed";
import FeedAdd from "../admin/pages/Feed/Add";
import FeedUpdate from "../admin/pages/Feed/update";
import FeedView from "../admin/pages/Feed/view";
import Order from "../admin/pages/order";
import OrderView from "../admin/pages/order/view";
import OrderRes from "../restaurants/pages/order";
import OrderViewRes from "../restaurants/pages/order/view";
import Pages from "../admin/pages/Pages";
import PagesUpdate from "../admin/pages/Pages/Update";
import Review from "../admin/pages/Review";
import ReviewRes from "../restaurants/pages/Review";
import Report from "../admin/pages/Report";
import ReportView from "../admin/pages/Report/view";
import Transaction from "../admin/pages/transaction";
import TransactionRes from "../restaurants/pages/transaction";
import Contact from "../admin/pages/Contact";
import ContactView from "../admin/pages/Contact/view";

import Banner from "../admin/pages/banner";
import AddBanner from "../admin/pages/banner/Add";

import Menu from "../admin/pages/menu";
import MenuAddAdmin from "../admin/pages/menu/Add";
import MenuUpdateAdmin from "../admin/pages/menu/update";
import MenuViewAdmin from "../admin/pages/menu/view";

import MenuRes from "../restaurants/pages/menu";
import MenuAdd from "../restaurants/pages/menu/Add";
import MenuUpdate from "../restaurants/pages/menu/update";
import MenuView from "../restaurants/pages/menu/view";

import Staff from "../restaurants/pages/Staff";
import StaffAdd from "../restaurants/pages/Staff/Add";
import StaffUpdate from "../restaurants/pages/Staff/update";

import FeedRes from "../restaurants/pages/Feed";
import FeedResAdd from "../restaurants/pages/Feed/add";
import FeedViewRes from "../restaurants/pages/Feed/view";

import TableRes from "../restaurants/pages/table";
import TableAddRes from "../restaurants/pages/table/Add";

import Bank from "../restaurants/pages/bankDetails";
import BankAdd from "../restaurants/pages/bankDetails/Add";

import Withdraw from "../admin/pages/Withdrawal";
import WithdrawView from "../admin/pages/Withdrawal/view";
import WithdrawRes from "../restaurants/pages/Withdrawal";
import WithdrawAddRes from "../restaurants/pages/Withdrawal/Add";
import WithdrawViewRes from "../restaurants/pages/Withdrawal/view";

import CouponUpdateRes from "../restaurants/pages/coupon/update";
import CouponRes from "../restaurants/pages/coupon";
import CouponAddRes from "../restaurants/pages/coupon/Add";
import CouponViewRes from "../restaurants/pages/coupon/view";

import Faq from "../admin/pages/Faq";
import FaqAdd from "../admin/pages/Faq/Add";
import FaqUpdate from "../admin/pages/Faq/update";
import FaqView from "../admin/pages/Faq/view";

export default function Router() {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const routes = useRoutes([
    {
      path: "/admin",
      element: (
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          adminRoute={true}
          isAdmin={true}
        >
          <AdminLayouts />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/admin/dashboard" />, index: true },
        { path: "dashboard", element: <Dashboard /> },
        { path: "profile", element: <Profile /> },
        { path: "profile/edit", element: <EditProfile /> },
        { path: "change-password", element: <ChangePassword /> },

        { path: "category", element: <Category /> },
        { path: "category/add", element: <AddCategory /> },
        { path: "category/update/:id", element: <UpdateCategory /> },

        { path: "banner", element: <Banner /> },
        { path: "banner/add", element: <AddBanner /> },

        { path: "user", element: <User /> },
        { path: "user/view/:id", element: <ViewUser /> },
        { path: "shopkeeper", element: <Shopkeeper /> },
        { path: "shopkeeper/add", element: <ShopkeeperAdd /> },
        { path: "shopkeeper/view/:id", element: <ViewShopkeeper /> },

        { path: "coupon", element: <Coupon /> },
        { path: "coupon/add", element: <CouponAdd /> },
        { path: "coupon/update/:id", element: <CouponUpdate /> },
        { path: "coupon/view/:id", element: <CouponView /> },

        { path: "faq", element: <Faq /> },
        { path: "faq/add", element: <FaqAdd /> },
        { path: "faq/update/:id", element: <FaqUpdate /> },
        { path: "faq/view/:id", element: <FaqView /> },

        { path: "feed", element: <Feed /> },
        { path: "feed/add", element: <FeedAdd /> },
        { path: "feed/update/:id", element: <FeedUpdate /> },
        { path: "feed/view/:id", element: <FeedView /> },

        { path: "menu", element: <Menu /> },
        { path: "menu/add", element: <MenuAddAdmin /> },
        { path: "menu/update/:id", element: <MenuUpdateAdmin /> },
        { path: "menu/view/:id", element: <MenuViewAdmin /> },

        { path: "order", element: <Order /> },
        { path: "order/view/:id", element: <OrderView /> },

        { path: "page/update/:id", element: <PagesUpdate /> },
        { path: "page", element: <Pages /> },
        { path: "review", element: <Review /> },
        { path: "report", element: <Report /> },
        { path: "report/view/:id", element: <ReportView /> },
        { path: "transaction", element: <Transaction /> },
        { path: "contact", element: <Contact /> },
        { path: "contact/view/:id", element: <ContactView /> },
        { path: "withdraw", element: <Withdraw /> },
        { path: "withdraw/view/:id", element: <WithdrawView /> },
      ],
    },
    {
      path: "/restaurant",
      element: (
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          VendorRoute={true}
          isUser={true}
        >
          <RestaurantLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/restaurant/dashboard" />, index: true },
        { path: "dashboard", element: <RestaurantDashboard /> },
        { path: "faq", element: <Faq /> },
        { path: "order", element: <OrderRes /> },
        { path: "order/view/:id", element: <OrderViewRes /> },
        { path: "profile", element: <RestaurantProfile /> },
        { path: "profile/edit", element: <RestaurantEditProfile /> },
        { path: "change-password", element: <RestaurantChangePassword /> },
        { path: "feed/add", element: <FeedResAdd /> },
        { path: "feed", element: <FeedRes /> },
        { path: "feed/view/:id", element: <FeedViewRes /> },
        { path: "review", element: <ReviewRes /> },
        { path: "transaction", element: <TransactionRes /> },
        { path: "menu", element: <MenuRes /> },
        { path: "menu/add", element: <MenuAdd /> },
        { path: "menu/update/:id", element: <MenuUpdate /> },
        { path: "menu/view/:id", element: <MenuView /> },
        { path: "staff", element: <Staff /> },
        { path: "staff/add", element: <StaffAdd /> },
        { path: "staff/update/:id", element: <StaffUpdate /> },
        { path: "table", element: <TableRes /> },
        { path: "table/add", element: <TableAddRes /> },
        { path: "bank", element: <Bank /> },
        { path: "bank/add", element: <BankAdd /> },
        { path: "withdraw", element: <WithdrawRes /> },
        { path: "withdraw/add", element: <WithdrawAddRes /> },
        { path: "withdraw/view/:id", element: <WithdrawViewRes /> },
        { path: "coupon", element: <CouponRes /> },
        { path: "coupon/add", element: <CouponAddRes /> },
        { path: "coupon/update/:id", element: <CouponUpdateRes /> },
        { path: "coupon/view/:id", element: <CouponViewRes /> },
      ],
    },
    { path: "/", element: <SignIn /> },
    {
      path: "*",
      element: <Error />,
    },
  ]);

  return routes;
}
