import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_REPORT_DELETE,
  API_REPORT_LIST,
  API_REPORT_VIEW,
} from "../../utils/APIConstant";

export const getReport = createAsyncThunk("get-report", async ({ token }) => {
  const res = await axios.request({
    method: "get",
    url: API_REPORT_LIST,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const deleteReport = createAsyncThunk(
  "delete-report",
  async ({ type, token }) => {
    const res = await axios.request({
      method: "get",
      url: API_REPORT_DELETE + "/" + type,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const viewReport = createAsyncThunk(
  "view-report",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "get",
      url: API_REPORT_VIEW + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

const ReportSlice = createSlice({
  name: "report",
  initialState: {
    loading: false,
    error: null,
    report: [],
    singleReport: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.report = action.payload.data;
    });
    builder.addCase(getReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(viewReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewReport.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
      state.singleReport = action.payload.data;
    });
    builder.addCase(viewReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteReport.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default ReportSlice.reducer;
