import React, { useEffect, useState } from "react";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import BaseCard from "../../components/baseCard";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateCategory,
  viewCategory,
} from "../../../redux/feature/CategorySlice";
import { Box, Card, Button, CardMedia, Grid } from "@mui/material";
import { baseUrlImage } from "../../../utils/config";

const validationSchema = yup.object().shape({
  title: yup.string("Enter your title").required(),
});

const Update = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { token } = useSelector((state) => state.auth);
  const { singleCategory } = useSelector((state) => state.category);
  const [file, setFile] = useState(null);

  useEffect(() => {
    dispatch(viewCategory({ id, token }));
  }, [dispatch, token, id]);

  const formik = useFormik({
    initialValues: {
      title: singleCategory?.title || "",
      image: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const myForm = new FormData();
      myForm.set("title", values.title);
      myForm.set("image", values.image);
      myForm.set("id", singleCategory?._id);
      dispatch(UpdateCategory({ myForm, token }));
      navigate(-1);
    },
  });

  const handleCoverImageChange = (e) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      setFile(e.target.result);
    };
    formik.setFieldValue("image", e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard
          title={"Categories / Update"}
          link="/admin/category"
          name="Back"
        />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group form-group col-md-6">
                    <label for="title">Title</label>
                    <FormGroup controlId="title" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.title && formik.errors.title}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                </div>

                <Grid item xs={12}>
                  <Box mb={2}>
                    <Box display="flex" flexWrap="wrap">
                      {file || singleCategory ? (
                          <Card
                            sx={{
                              maxWidth: 200,
                              maxHeight: 200,
                              margin: "8px",
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="140"
                              image={file ? file : baseUrlImage+singleCategory?.image}
                              alt={`Category Cover`}
                            />
                          </Card>
                        ):''}
                    </Box>
                  </Box>

                  <Box>
                    <input
                      type="file"
                      onChange={handleCoverImageChange}
                      accept="image/*"
                      style={{ display: "none" }}
                      id="cover-image-upload"
                    />
                    <label htmlFor="cover-image-upload">
                      <Button
                        variant="contained"
                        component="span"
                        color="secondary"
                      >
                        Image
                      </Button>
                    </label>
                  </Box>
                </Grid>

                <button type="submit" className="btn btn-gradient-primary mt-4">
                  Submit
                </button>
                <Link to="/admin/category" className="btn btn-light mt-4">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
