import {createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_ANALYTICS_DASH, API_ANALYTICS_GRAPH, API_RESTAURANT_ANALYTICS_DASH, API_RESTAURANT_ANALYTICS_GRAPH } from "../../utils/APIConstant";

export const getAnalyticsUser = createAsyncThunk("get-analytics", async (token) => {
  const res = await axios.request({
    method: "get",
    url: API_ANALYTICS_GRAPH,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const getAnalyticDash = createAsyncThunk("get-analytics-dash", async (token) => {
  const res = await axios.request({
    method: "get",
    url: API_ANALYTICS_DASH,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const getAnalyticDashRes = createAsyncThunk("get-analytics-dash-res", async (token) => {
  const res = await axios.request({
    method: "get",
    url: API_RESTAURANT_ANALYTICS_DASH,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});



export const getAnalyticsRes = createAsyncThunk("get-analytics-res", async (token) => {
  const res = await axios.request({
    method: "get",
    url: API_RESTAURANT_ANALYTICS_GRAPH,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

