import React, { useEffect } from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Typography, Button } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { deleteContact, getContact } from "../../../redux/feature/ContactSlice";

const Contact = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { contact, loading, isSuccessful } = useSelector(
    (state) => state.contact
  );

  useEffect(() => {
    dispatch(getContact(token));
  }, [dispatch, token, isSuccessful]);

  const deleted = (id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteContact({ id: id, token }));
      }
    });
  };

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
    // rowsPerPage: rowsPerPage,
    // rowsPerPageOptions: [10, 25, 50, 100],
    // serverSide: true,
    // page: page,
    // count: classes?.totalData,
    // onSearchChange: (searchText) => handleSearch(searchText),
    // searchText: searchText,
    // onChangePage: handleChangePage,
    // onChangeRowsPerPage: handleChangeRowsPerPage,
  };
  const columns = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "Full Name",
      name: "name",
    },
    {
      label: "Email",
      name: "email",
    },
    {
      label: "Description",
      name: "description",
    },
    {
      label: "Created At",
      name: "createdAt",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const result =
    contact &&
    contact?.map((val, index) => ({
      id: index + 1,
      name: val?.name,
      email: val?.email,
      description: val?.description.substr(0, 60),
      createdAt: moment(val?.createdAt).format("LL"),
      action: (
        <Typography color="textSecondary" variant="h6">
          <Link to={`/admin/contact/view/${val?._id}`}>
            <Button component="a" variant="outlined" color="secondary">
              View
            </Button>
          </Link>{" "}
          <Button
            sx={{
              marginLeft: "5px",
            }}
            variant="outlined"
            color="error"
            onClick={() => deleted(val._id)}
          >
            Delete
          </Button>
        </Typography>
      ),
    }));

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Contact"} />
        {loading ? (
          <Circles
            height="80"
            width="80"
            color="#6f42c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loader_cl"
            visible={true}
          />
        ) : (
          <>
            <MUIDataTable data={result} columns={columns} options={options} />
          </>
        )}
      </div>
    </div>
  );
};

export default Contact;
