import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
import {
  API_CHANGE_PASSWORD,
  API_LOGIN,
  API_LOGOUT,
  API_PROFILE_EDIT,
  API_SHOP_DETAILS,
} from "../../utils/APIConstant";

export const Login = createAsyncThunk("login", async (values) => {
  try {
    const res = await axios.request({
      method: "POST",
      url: API_LOGIN,
      headers: {
        Accept: "application/json",
      },
      data: values,
    });
    toast.success("Login Successfully !!");
    return res.data;
  } catch (err) {
    toast.error(err.response.data.error);
    return err.response.data;
  }
});

export const userLogout = createAsyncThunk("user/logout", async () => {
  const res = await axios.request({
    method: "get",
    url: API_LOGOUT,
    headers: {
      "Content-Type": "application/json",
    },
  });
  sessionStorage.removeItem("persist:root");
  return res.data;
});

export const change_Password = createAsyncThunk(
  "change-password",
  async ({ values, token }) => {
    try {
      const res = await axios.request({
        method: "POST",
        url: API_CHANGE_PASSWORD,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: values,
      });
      toast.success("Change Password Successfully !!");
      return res.data;
    } catch (err) {
      toast.error(err.response.data.error);
      return err.response.data;
    }
  }
);

export const profileEdit = createAsyncThunk(
  "profile-edit",async ({ myForm, token }) => {
    try {
      const res = await axios.request({
        method: "POST",
        url: API_PROFILE_EDIT,
        headers: {
           Accept: "application/json",
           "Content-Type": "multipart/form-data",
           Authorization: `Bearer ${token}`,
        },
        data: myForm,
      });
      toast.success("Profile updated Successfully !!");
      return res.data;
    } catch (err) {
      toast.error(err.response.data.error);
      return err.response.data;
    }
  }
);

export const getShop = createAsyncThunk(
  "shop-details",async (token) => {
    try {
      const res = await axios.request({
        method: "get",
        url: API_SHOP_DETAILS,
        headers: {
           Accept: "application/json",
           Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (err) {
      toast.error(err.response.data.error);
      return err.response.data;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    isSuccessful: false,
    error: null,
    auth: null,
    token: null,
    isAuthenticated: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Login.fulfilled, (state, action) => {
      state.auth = action.payload.data;
      state.token = action.payload.data.token;
      state.isAuthenticated = action.payload.data.token ? true : false;
      state.isSuccessful = false;
    });
    builder.addCase(Login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(profileEdit.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(profileEdit.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.auth = action.payload.data;
        state.isSuccessful = false;
      }
    });
    builder.addCase(profileEdit.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(userLogout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userLogout.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      sessionStorage.removeItem("persist:root");
      state.auth = "";
      state.token = "";
    });
    builder.addCase(userLogout.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default authSlice.reducer;
