import React, { useEffect } from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BaseCard from "../../components/baseCard";
import { viewFaq } from "../../../redux/feature/FaqSlice";

const View = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { singleFaq } = useSelector((state) => state.faq);

  useEffect(() => {
    dispatch(viewFaq({ id, token }));
  }, [dispatch, id, token]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Faq/view"} link="/admin/faq" name="Back" />
          <Card className="mt-5">
            <CardContent>
              <div className="row w-100 gx-4 gy-5">
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Status:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleFaq?.status == 1 ? (
                      <Button variant="contained" color="success">
                        Active
                      </Button>
                    ) : (
                      <Button variant="contained" color="warning">
                        InActive
                      </Button>
                    )}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Created At:
                  </Typography>
                  <Typography variant="body2">
                    {moment(singleFaq?.createdAt).format("LL")}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="mt-5">
            <CardContent>
              <Typography className="w-100 mb-1" variant="body1">
                Question:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {singleFaq?.question}
              </Typography>
            </CardContent>
          </Card>
          <Card className="mt-5">
            <CardContent>
              <Typography className="w-100 mb-1" variant="body1">
                Answer:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {singleFaq?.answer}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default View;
