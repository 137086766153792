import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { EditorModule } from "../../../utils/editor";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getPage, updatePage } from "../../../redux/feature/pageSlice";
import { Circles } from "react-loader-spinner";
import BaseCard from "../../components/baseCard";
// ----------------------------------------------------------------------

const Update = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [valueDes, setDescription] = useState("");
  const { loading, singlePage } = useSelector((state) => state.page);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getPage({ id: params.id, token }));
  }, [dispatch, token, params.id]);

  const formik = useFormik({
    initialValues: {
      title: "",
    },
    onSubmit: (values) => {
      values.description = valueDes;
      values.id = params.id;
      dispatch(updatePage({ values, token }));
      navigate(-1);
    },
  });

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard
          title={`Pages / ${singlePage?.slug}`}
          link="/admin/page"
          name="Back"
        />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              {loading ? (
                <>
                  <Circles
                    height="80"
                    width="80"
                    color="#2065D1"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass="loader_cir"
                    visible={true}
                  />
                </>
              ) : (
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div class="row">
                    <div class="form-group form-group">
                      <ReactQuill
                        modules={EditorModule}
                        theme="snow"
                        onChange={setDescription}
                        defaultValue={singlePage && singlePage.description}
                        placeholder="Content goes here..."
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-gradient-primary me-2 justify-content-center"
                  >
                    Submit
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
