import React, { useState } from "react";
import BaseCard from "../../components/baseCard";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AddCategory } from "../../../redux/feature/CategorySlice";
import { useNavigate } from "react-router-dom";
import { Box, Card, Button, CardMedia, Grid } from "@mui/material";

const validationSchema = yup.object().shape({
  title: yup.string("Enter your title").required(),
  status: yup.string("Select status").required("Status is a required field"),
});

const Add = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  const { token } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      title: "",
      status: "",
      image: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const myForm = new FormData();
      myForm.set("title", values.title);
      myForm.set("status", values.status);
      myForm.set("image", values.image);
      dispatch(AddCategory({ myForm, token }));
      navigate(-1);
    },
  });

  const handleCoverImageChange = (e) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      setFile(e.target.result);
    };
    formik.setFieldValue("image", e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Categories / Add"} />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group form-group col-md-6">
                    <label for="title">Title</label>
                    <FormGroup controlId="title" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.title && formik.errors.title}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputUsername1">Status</label>
                    <FormGroup controlId="status" className="position-relative">
                      <FormControl
                        as="select"
                        name="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.status && Boolean(formik.errors.status)
                        }
                      >
                        <option>Select</option>
                        <option value="0">Inactive</option>
                        <option value="1">Active</option>
                      </FormControl>
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.status && formik.errors.status}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Box display="flex" flexWrap="wrap">
                        {file && (
                          <Card
                            sx={{
                              maxWidth: 200,
                              maxHeight: 200,
                              margin: "8px",
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="140"
                              image={file}
                              alt={`Product Cover`}
                            />
                          </Card>
                        )}
                      </Box>
                    </Box>

                    <Box>
                      <input
                        type="file"
                        onChange={handleCoverImageChange}
                        accept="image/*"
                        style={{ display: "none" }}
                        id="cover-image-upload"
                      />
                      <label htmlFor="cover-image-upload">
                        <Button
                          variant="contained"
                          component="span"
                          color="secondary"
                        >
                          Image
                        </Button>
                      </label>
                    </Box>
                  </Grid>
                </div>
                <button type="submit" class="btn btn-gradient-primary mt-4">
                  Submit
                </button>{" "}
                <Link to="/admin/category" class="btn btn-light mt-4">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
