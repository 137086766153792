import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_CONTACT_DELETE, API_CONTACT_LIST, API_CONTACT_VIEW } from "../../utils/APIConstant";

export const getContact = createAsyncThunk("get-contact", async (token) => {
  const res = await axios.request({
    method: "get",
    url: API_CONTACT_LIST,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});


export const viewContact = createAsyncThunk(
  "view-contact",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "get",
      url: API_CONTACT_VIEW + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const deleteContact = createAsyncThunk(
  "delete-contact",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_CONTACT_DELETE + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

const ContactSlice = createSlice({
  name: "contact",
  initialState: {
    loading: false,
    error: null,
    contact: [],
    singleContact: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContact.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getContact.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.contact = action.payload.data;
    });
    builder.addCase(getContact.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(viewContact.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewContact.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
      state.singleContact = action.payload.data;
    });
    builder.addCase(viewContact.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteContact.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteContact.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default ContactSlice.reducer;
