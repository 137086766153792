import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import BaseCard from "../../components/baseCard";
import { deleteFeedComment, deleteFeedLikes, viewFeed } from "../../../redux/feature/FeedSlice";
import { Avatar } from "@mui/material";
import { baseUrlImage } from "../../../utils/config";
import MUIDataTable from "mui-datatables";
import Swal from "sweetalert2";

const View = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  // const {  } = useSelector((state) => state.feed);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(viewFeed({ id, token })).then((val)=>{
      if(val.payload.success){
        setData(val?.payload?.data)
      }
    });
  }, [dispatch, id, token]);

  const columns = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "User Name",
      name: "user",
    },
    {
      label: "Comment",
      name: "comment",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const columnsLike = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "User Name",
      name: "user",
    },
    {
      label: "Likes",
      name: "like",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const options = {
    filter: false,
    search: true,
    sort: false,
    selectableRows: "none",
    download: false,
    print: false,
  };

  const deleteUser = (ids) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFeedComment({ id: ids, token }));
        dispatch(viewFeed({ id, token }));
      }
    });
  };

  const deleteLikes = (ids) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFeedLikes({ id: ids, token }));
        dispatch(viewFeed({ id, token }));
      }
    });
  };

  const resultLikes =
    data &&
    data?.feedlikes?.map((val, index) => ({
      id: index + 1,
      user: (
        <Link to={`/admin/user/view/${val?.user && val?.user[0]?._id}`}>
          <Button color="secondary">{val?.user && val?.user[0]?.name}</Button>
        </Link>
      ),
      like: val?.isLike === 1 ? "Liked" : "",
      action: (
        <Typography color="textSecondary" variant="h6">
          <Button
            sx={{
              marginLeft: "5px",
            }}
            variant="outlined"
            color="error"
            onClick={() => deleteLikes(val._id)}
          >
            Delete
          </Button>
        </Typography>
      ),
    }));

  const resultComment =
    data &&
    data?.feedComment?.map((val, index) => ({
      id: index + 1,
      user: (
        <Link to={`/admin/user/view/${val?.user && val?.user[0]?._id}`}>
          <Button color="secondary">{val?.user && val?.user[0]?.name}</Button>
        </Link>
      ),
      comment: val?.comment,
      action: (
        <Typography color="textSecondary" variant="h6">
          <Button
            sx={{
              marginLeft: "5px",
            }}
            variant="outlined"
            color="error"
            onClick={() => deleteUser(val._id)}
          >
            Delete
          </Button>
        </Typography>
      ),
    }));

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Feed/view"} link="/admin/feed" name="Back" />
          <Card className="mt-5">
            <CardContent>
              <div className="row w-100 gx-4 gy-5">
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Restaurant:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {data?.vendor?.shop_name}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    User Name:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {data?.user?.name}
                  </Typography>
                </div>

                {(data?.type === 2 || data?.type === 3) && (
                  <div className="col-md-4">
                    <Typography className="w-100 mb-1" variant="body1">
                      Image/video:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {data?.type === 2 ? (
                        <Avatar
                          src={baseUrlImage + "" + data?.image}
                          sx={{ width: 80, height: 80 }}
                        />
                      ) : (
                        <video width="100" height="100" controls>
                          <source
                            src={baseUrlImage + "" + data?.image}
                            type="video/mp4"
                          />
                        </video>
                      )}
                    </Typography>
                  </div>
                )}
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    No. of Like:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {data?.feedLikesCount}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    No. of Comment:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {data?.feedCommentsCount}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Created At:
                  </Typography>
                  <Typography variant="body2">
                    {moment(data?.createdAt).format("LL")}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="mt-5">
            {data?.description && (
              <CardContent>
                <div className="row w-100 gx-4 gy-5">
                  <div className="col-md-4">
                    <Typography className="w-100 mb-1" variant="body1">
                      Description:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {data?.description}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            )}
          </Card>
          <div className="mt-5">
            <MUIDataTable
              data={resultLikes}
              columns={columnsLike}
              options={options}
            />
          </div>
          <div className="mt-5"></div>
          <MUIDataTable
            data={resultComment}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </>
  );
};

export default View;
