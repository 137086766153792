import API from "./axios";
import toast from "react-hot-toast";

function handleApiError(error) {
  let data = error?.response?.data ?? { message: error?.message };
  return { success: false, data, code: error?.response?.status };
}

export const apiGET = async (endPoint, id) => {
  try {
    const res = !!id
      ? await API.get(endPoint + "/" + id)
      : await API.get(endPoint);
    return { data: res.data };
  } catch (error) {
    return error;
  }
};

export const apiPOST = async (endPoint, body, head = false) => {
  try {
    let headers = {
      "Content-Type": "multipart/form-data",
    };
    const res = head ? await API.post(endPoint, body, headers) : await API.post(endPoint, body);
    toast.success(res.data.message);
    return { data: res.data };
  } catch (error) {
    toast.error(error.response.data.error);
    return error;
  }
};

export const apiPUT = async (endPoint, body, head = false) => {
  try {
    let headers = {
      "Content-Type": "multipart/form-data",
    };
    const res = head
      ? await API.put(endPoint, body, headers)
      : await API.put(endPoint, body);
    toast.success(res.data.message);
    return { data: res.data };
  } catch (error) {
    toast.error(error.response.data.error);
    return error;
  }
};

export const apiPATCH = async (endPoint, body) => {
  try {
    const res = await API.patch(endPoint, body);
    toast.success(res.data.message);
    return { data: res.data };
  } catch (error) {
    toast.error(error.response.data.error);
    return error;
  }
};

export const apiDELETE = async (endPoint, body) => {
  try {
    const res = await API.delete(endPoint, { data: body });
    toast.success(res.data.message);
    return { data: res.data };
  } catch (error) {
    toast.error(error.response.data.error);
    return error;
  }
};
