import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import BaseCard from "../../components/baseCard";
import { profileEdit } from "../../../redux/feature/authSlice";
import { baseUrlImage } from "../../../utils/config";

const validationSchema = yup.object({
  name: yup.string("Enter your Name").required("Name is required"),
  email: yup.string("Enter Email").email().required("Email type is required"),
  phone: yup.string("Enter Phone No.").required("Phone no. is required"),
  commission: yup
    .string("Enter Commission.")
    .required("Commission is required"),
  tax: yup.string("Enter tax.").required("Tax is required"),
  text_points: yup.string("Enter text points.").required("Text points is required"),
  video_points: yup.string("Enter Video points.").required("Video points is required"),
  image_points: yup.string("Enter Image points.").required("Image points is required"),
});

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const { auth, token } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      name: auth.name || "",
      email: auth.email || "",
      phone: auth.phone || "",
      commission: auth.commission || "",
      tax: auth.tax || "",
      text_points: auth.text_points || "",
      video_points: auth.video_points || "",
      image_points: auth.image_points || "",
      image: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const myForm = new FormData();
      myForm.set("name", values.name);
      myForm.set("email", values.email);
      myForm.set("phone", values.phone);
      myForm.set("commission", values.commission);
      myForm.set("tax", values.tax);
      myForm.set("text_points", values.text_points);
      myForm.set("image_points", values.image_points);
      myForm.set("video_points", values.video_points);
      myForm.set("image", values.image);
      myForm.set("type", 0);
      dispatch(profileEdit({ myForm, token }));
      navigate(-1);
    },
  });

  const handleCoverImageChange = (e) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      setFile(e.target.result);
    };
    formik.setFieldValue("image", e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Admin Profile"} />
          <div class="row justify-content-center">
            <div class="card">
              <div class="card-body">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <Stack spacing={3} sx={{ padding: "30px" }}>
                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "50%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="name"
                          name="name"
                          label="Enter Name"
                          variant="outlined"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                        />
                      </Box>
                      <Box
                        sx={{ margin: "auto", width: "50%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Enter Email"
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "100%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="phone"
                          name="phone"
                          label="Enter Phone no."
                          variant="outlined"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "100%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="commission"
                          name="commission"
                          label="Admin commission %"
                          variant="outlined"
                          value={formik.values.commission}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.commission &&
                            Boolean(formik.errors.commission)
                          }
                          helperText={
                            formik.touched.commission &&
                            formik.errors.commission
                          }
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "100%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="tax"
                          name="tax"
                          label="Admin Tax %"
                          variant="outlined"
                          value={formik.values.tax}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.tax && Boolean(formik.errors.tax)
                          }
                          helperText={formik.touched.tax && formik.errors.tax}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "100%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="text_points"
                          name="text_points"
                          label="Text Points"
                          variant="outlined"
                          value={formik.values.text_points}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.text_points && Boolean(formik.errors.text_points)
                          }
                          helperText={formik.touched.text_points && formik.errors.text_points}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "100%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="image_points"
                          name="image_points"
                          label="Image Points"
                          variant="outlined"
                          value={formik.values.image_points}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.image_points && Boolean(formik.errors.image_points)
                          }
                          helperText={formik.touched.image_points && formik.errors.image_points}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "100%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="video_points"
                          name="video_points"
                          label="Video Points"
                          variant="outlined"
                          value={formik.values.video_points}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.video_points && Boolean(formik.errors.video_points)
                          }
                          helperText={formik.touched.video_points && formik.errors.video_points}
                        />
                      </Box>
                    </Box>
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Box display="flex" flexWrap="wrap">
                          {file || auth?.image !== "" ? (
                            <Card
                              sx={{
                                maxWidth: 200,
                                maxHeight: 200,
                                margin: "8px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="140"
                                image={
                                  file ? file : baseUrlImage + "" + auth?.image
                                }
                                alt={`User image`}
                              />
                            </Card>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>

                      <Box>
                        <input
                          type="file"
                          onChange={handleCoverImageChange}
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-image-upload"
                        />
                        <label htmlFor="cover-image-upload">
                          <Button
                            variant="contained"
                            component="span"
                            color="secondary"
                          >
                            Profile Image
                          </Button>
                        </label>
                      </Box>
                    </Grid>

                    <Box display="flex" sx={{ justifyContent: "center" }}>
                      <Box sx={{ mt: 4 }}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Update
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Stack>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
