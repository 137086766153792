import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./feature/authSlice";
import userSlice from "./feature/userSlice";
import CategorySlice from "./feature/CategorySlice";
import CouponSlice from "./feature/CouponSlice";
import FaqSlice from "./feature/FaqSlice";
import orderSlice from "./feature/orderSlice";
import ContactSlice from "./feature/ContactSlice";
import PageSlice from "./feature/pageSlice";
import RatingSlice from "./feature/RatingSlice";
import FeedSlice from "./feature/FeedSlice";
import MenuSlice from "./feature/menuSlice";
import BannerSlice from "./feature/BannerSlice";
import ReportSlice from "./feature/reportSlice";
import TableSlice from "./feature/tableSlice";
import BankSlice from "./feature/bankSlice";
import withdrawSlice from "./feature/withdrawSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  category: CategorySlice,
  coupon: CouponSlice,
  faq: FaqSlice,
  order: orderSlice,
  contact: ContactSlice,
  page: PageSlice,
  rating: RatingSlice,
  feed: FeedSlice,
  menu: MenuSlice,
  banner: BannerSlice,
  report: ReportSlice,
  table: TableSlice,
  bank: BankSlice,
  withdraw: withdrawSlice,
});

export default rootReducer;
