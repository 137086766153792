import React, { useEffect } from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Box, FormControlLabel, Switch , Typography , Button } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { deleteCoupon, getCoupon, statusCoupon } from "../../../redux/feature/CouponSlice";

const Coupon = () => {

  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { coupon, loading, isSuccessful } = useSelector((state) => state.coupon);

  useEffect(() => {
    dispatch(getCoupon(token));
  }, [dispatch,token, isSuccessful]);


  const deleteUser = (id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCoupon({ id: id, token }));
      }
    });
  };

  const handleToggle = (id, checked) => {
        let myForm = { 
          id , 
          status : checked === false ? 0 : 1
        }
        dispatch(statusCoupon({myForm, token}))
  };


  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
    // rowsPerPage: rowsPerPage,
    // rowsPerPageOptions: [10, 25, 50, 100],
    // serverSide: true,
    // page: page,
    // count: classes?.totalData,
    // onSearchChange: (searchText) => handleSearch(searchText),
    // searchText: searchText,
    // onChangePage: handleChangePage,
    // onChangeRowsPerPage: handleChangeRowsPerPage,
  };
  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Title",
      name: "title",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Rewards Code",
      name: "code",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    // {
    //   label: "Validity",
    //   name: "validity",
    //   options: {
    //     customBodyRender: (value) => (
    //       <div style={{ textAlign: "center" }}>{value}</div>
    //     ),
    //   },
    // },
    {
      label: "Points",
      name: "points",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Discount",
      name: "discount",
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Created At",
      name: "createdAt",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const result =
  coupon &&
  coupon?.map((val, index) => ({
      id: index + 1,
      title: val?.title,
      code: val?.code,
      // validity: val?.validity,
      points: val?.points,
      discount: val?.discount,
      status: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={val?.status == 0 ? false : true}
                onChange={(event) =>
                  handleToggle(val._id, event.target.checked)
                }
                color="secondary"
                name="toggleButton"
              />
            }
          />
        </Box>
      ),
      createdAt: moment(val?.createdAt).format("LL"),
      action: (
        <Typography color="textSecondary" variant="h6">
          <Link to={`/restaurant/coupon/view/${val?._id}`}>
            <Button component="a" variant="outlined" color="secondary">
              View
            </Button>
          </Link>
{" "}
           <Link to={`/restaurant/coupon/update/${val?._id}`}>
            <Button component="a" variant="outlined" color="secondary">
              Update
            </Button> 
          </Link>

          <Button
            sx={{
              marginLeft: "5px",
            }}
            variant="outlined"
            color="error"
            onClick={() => deleteUser(val._id)}
          >
            Delete
          </Button>
        </Typography>
      ),
    }));

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Coupon"} link="/restaurant/coupon/add" />
        {loading ? (
          <Circles
            height="80"
            width="80"
            color="#6f42c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loader_cl"
            visible={true}
          />
        ) : (
          <>
            <MUIDataTable data={result} columns={columns} options={options} />
          </>
        )}
      </div>
    </div>
  );
};

export default Coupon;
