import React, { useEffect, useState } from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../../../redux/feature/orderSlice";
import moment from "moment";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { baseUrlImage } from "../../../utils/config";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Index = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { order, loading, isSuccessful } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getOrder({ type: 0, token }));
  }, [dispatch, token, isSuccessful]);

  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const handleClose = () => setShow(false);

  const handleOrder = (data) => {
    if (data.length > 0) {
      setData(data);
      setShow(true);
    }
  };

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    serverSide: true,
  };
  const columns = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "Product",
      name: "product",
    },
    {
      label: "User Name",
      name: "user",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Table No.",
      name: "table",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Total Price",
      name: "price",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Payment Status",
      name: "payment_type",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Order Type",
      name: "order_type",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Accept by",
      name: "accept_by",
    },
    {
      label: "Created At",
      name: "createdAt",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const result =
    order &&
    order?.map((val, index) => ({
      id: index + 1,
      title: val?.title,
      restaurant: val?.user_id?.name,
      product: (
        <Button
          className="btn bg-gradient-primary"
          onClick={() => handleOrder(val?.product)}
        >
          {val?.product?.length} View
        </Button>
      ),
      user: val?.user_id?.name,
      accept_by: val?.acceptBy?.name,
      price: val?.price,
      table: val?.table,
      status: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {val?.status === 2 ? (
            <Button className="btn bg-gradient-primary">Delivery</Button>
          ) : val?.status === 3 ? (
            <Button className="btn bg-gradient-danger">Canceled</Button>
          ) : val?.status === 1 ? (
            <Button className="btn bg-gradient-danger">Preparing</Button>
          ) : (
            <Button className="btn bg-gradient-danger">Requested</Button>
          )}
        </Box>
      ),
      payment_type: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {val?.payment_type === 1 ? (
            <Button className="btn bg-gradient-primary">Paid</Button>
          ) : val?.payment_type === 0 ? (
            <Button className="btn bg-gradient-danger">Pending</Button>
          ) : (
            ""
          )}
        </Box>
      ),
      order_type: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {val?.orderType === 1 ? (
            <Button className="btn bg-gradient-primary">Dining</Button>
          ) : val?.orderType === 0 ? (
            <Button className="btn bg-gradient-danger">Take way</Button>
          ) : (
            ""
          )}
        </Box>
      ),
      createdAt: moment(val?.createdAt).format("LL"),
      action: (
        <Typography color="textSecondary" variant="h6">
          <Link to={`/restaurant/order/view/${val?._id}`}>
            <Button component="a" variant="outlined" color="secondary">
              View
            </Button>
          </Link>{" "}
        </Typography>
      ),
    }));

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Order`s"} />
          {loading ? (
            <Circles
              height="80"
              width="80"
              color="#6f42c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass="loader_cl"
              visible={true}
            />
          ) : (
            <>
              <MUIDataTable data={result} columns={columns} options={options} />
            </>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="max-auto">
        <Modal.Header closeButton>
          <Modal.Title>Oder details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              {data.map((val, i) => (
                <div className="col-md-4" key={i}>
                  <img
                    className="rounded img-fluid"
                    src={baseUrlImage + val?.image}
                    alt=""
                    style={{ height: "100px" }}
                  />
                  <h5 className="text-center">
                    <Link to={`/restaurant/menu/view/${val?.menu_id}`}>
                      {val?.name}
                    </Link>
                    <p>(Qty:{val?.quantity || 1})</p>{" "}
                  </h5>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Index;
