import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_COUPON_ADD,
  API_COUPON_DELETE,
  API_COUPON_LIST,
  API_COUPON_STATUS,
  API_COUPON_UPDATE,
  API_COUPON_VIEW,
} from "../../utils/APIConstant";
import toast from "react-hot-toast";

export const getCoupon = createAsyncThunk("get-coupon", async (token) => {
  const res = await axios.request({
    method: "get",
    url: API_COUPON_LIST,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const viewCoupon = createAsyncThunk(
  "view-coupon",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "get",
      url: API_COUPON_VIEW + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const AddCoupon = createAsyncThunk(
  "add-coupon",
  async ({ values, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_COUPON_ADD,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: values,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const UpdateCoupon = createAsyncThunk(
  "update-coupon",
  async ({ values, token }) => {
    const res = await axios.request({
      method: "PUT",
      url: API_COUPON_UPDATE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: values,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const statusCoupon = createAsyncThunk(
  "status-coupon",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_COUPON_STATUS,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const deleteCoupon = createAsyncThunk(
  "delete-Coupon",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_COUPON_DELETE + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

const CouponSlice = createSlice({
  name: "coupon",
  initialState: {
    loading: false,
    error: null,
    coupon: [],
    singleCoupon: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCoupon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCoupon.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.coupon = action.payload.data;
    });
    builder.addCase(getCoupon.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(AddCoupon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddCoupon.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(AddCoupon.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });


    builder.addCase(viewCoupon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewCoupon.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
      state.singleCoupon = action.payload.data;
    });
    builder.addCase(viewCoupon.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(statusCoupon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusCoupon.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(statusCoupon.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteCoupon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCoupon.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteCoupon.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default CouponSlice.reducer;
