import React, { useEffect } from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { deleteFeed, getFeed } from "../../../redux/feature/FeedSlice";
import Swal from "sweetalert2";
import { Avatar, CardMedia } from "@mui/material";
import { baseUrlImage } from "../../../utils/config";

const Coupon = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { feed, loading, isSuccessful } = useSelector((state) => state.feed);

  useEffect(() => {
    dispatch(getFeed({ token }));
  }, [dispatch, token, isSuccessful]);

  const deleteUser = (id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFeed({ id: id, token }));
      }
    });
  };

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
  };
  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "User Name",
      name: "user",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },

    {
      label: "Description",
      name: "description",
    },
    {
      label: "Image/Video",
      name: "image",
    },
    {
      label: "No. of Like",
      name: "like",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "No. of Comment",
      name: "comment",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Created At",
      name: "createdAt",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const result =
    feed &&
    feed?.map((val, index) => ({
      id: index + 1,
      user: val?.user ? (
        <Link to={`/restaurant/user/view/${val?.user?._id}`}>
          <Button color="secondary">
            {val?.user?.name + " " + val?.user?.last_name}
          </Button>
        </Link>
      ) : (
        <>{val?.vendor?.shop_name}</>
      ),
      description: val?.description?.substr(0, 60),
      image:
        val?.type === 2 ? (
          <>
            {" "}
            <Avatar
              src={baseUrlImage + "" + val?.image}
              sx={{ width: 80, height: 80 }}
            />{" "}
          </>
        ) : (
          <>
            {" "}
            <CardMedia
              component="video"
              src={baseUrlImage + "" + val?.image}
              sx={{ width: 80, height: 80 }}
              // autoPlay
            />
          </>
        ),
      like: val?.feedLikesCount,
      comment: val?.feedCommentsCount,
      createdAt: moment(val?.createdAt).format("LL"),
      action: (
        <Typography color="textSecondary" variant="h6">
          <Link to={`/restaurant/feed/view/${val?._id}`}>
            <Button component="a" variant="outlined" color="secondary">
              View
            </Button>
          </Link>{" "}
          {!val?.user_id && (
            <Button
              sx={{
                marginLeft: "5px",
              }}
              variant="outlined"
              color="error"
              onClick={() => deleteUser(val._id)}
            >
              Delete
            </Button>
          )}
        </Typography>
      ),
    }));

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Feed"} link={"/restaurant/feed/add"} />
        {loading ? (
          <Circles
            height="80"
            width="80"
            color="#6f42c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loader_cl"
            visible={true}
          />
        ) : (
          <>
            <MUIDataTable data={result} columns={columns} options={options} />
          </>
        )}
      </div>
    </div>
  );
};

export default Coupon;
