import React, { useEffect } from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMenu,
  getMenu,
  statusMenu,
} from "../../../redux/feature/menuSlice";
import moment from "moment";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { Box } from "@mui/material";
import { baseUrlImage } from "../../../utils/config";
import { Avatar } from "@mui/material";

const Index = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { menu, loading, isSuccessful } = useSelector((state) => state.menu);

  useEffect(() => {
    dispatch(getMenu({ type: 1, token }));
  }, [dispatch, token, isSuccessful]);

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
    // rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 100],
    serverSide: true,
    // page: page,
    // count: classes?.totalData,
    // onSearchChange: (searchText) => handleSearch(searchText),
    // searchText: searchText,
    // onChangePage: handleChangePage,
    // onChangeRowsPerPage: handleChangeRowsPerPage,
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteMenu({ id: id, token }));
      }
    });
  };

  const handleToggle = (id, checked) => {
    let myForm = {
      id,
      status: checked === false ? 0 : 1,
    };
    dispatch(statusMenu({ myForm, token }));
  };

  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Categories",
      name: "category",
    },
    {
      label: "Image",
      name: "image",
    },
    {
      label: "Menu Name",
      name: "title",
    },
    {
      label: "Price",
      name: "price",
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "CreatedAt",
      name: "createdAt",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const result =
    menu &&
    menu?.map((val, index) => ({
      id: index + 1,
      title: val?.title,
      category: val?.category?.title,
      price: val?.price,
      image: (
        <Avatar
          src={baseUrlImage + "" + val?.files[0]?.name}
          sx={{ width: 100, height: 100 }}
        />
      ),
      status: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={val?.status == 0 ? false : true}
                onChange={(event) =>
                  handleToggle(val._id, event.target.checked)
                }
                color="secondary"
                name="toggleButton"
              />
            }
          />
        </Box>
      ),
      createdAt: moment(val?.createdAt).format("LL"),
      action: (
        <Typography color="textSecondary" variant="h6">
          <Link to={`/restaurant/menu/view/${val?._id}`}>
            <Button component="a" variant="outlined" color="secondary">
              View
            </Button>
          </Link>{" "}
          <Link to={`/restaurant/menu/update/${val?._id}`}>
            <Button component="a" variant="outlined" color="secondary">
              Update
            </Button>
          </Link>
          <Button
            sx={{
              marginLeft: "5px",
            }}
            variant="outlined"
            color="error"
            onClick={() => deleteUser(val._id)}
          >
            Delete
          </Button>
        </Typography>
      ),
    }));

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Menu"} link={"/restaurant/menu/add"} />
        {loading ? (
          <Circles
            height="80"
            width="80"
            color="#6f42c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loader_cl"
            visible={true}
          />
        ) : (
          <>
            <MUIDataTable data={result} columns={columns} options={options} />
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
