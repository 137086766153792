import React from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { useQuery } from "react-query";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { userStatus } from "../../../redux/feature/userSlice";
import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import Swal from "sweetalert2";
import moment from "moment";
import { baseUrlImage } from "../../../utils/config";
import { listStaff, staffDelete } from "../../../utils";
import { Link } from "react-router-dom";


const Staff = () => {
 
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const { data, isFetching, isError, error ,refetch } = useQuery(
    ["staff-listing"],
    async () => {
      const res = await listStaff();
      return res?.data?.data;
    },{
      manual: true,
    }
  );

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        staffDelete({ id });
        refetch()
      }
    });
  };

  const handleToggle = (id, checked) => {
    let myForm = {
      id,
      status: checked === false ? 0 : 1,
    };
    dispatch(userStatus({ myForm, token }));
  };

  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Profile",
      name: "profile",
    },
    {
      label: "Name",
      name: "name",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Email",
      name: "email",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Phone",
      name: "phone",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Created At",
      name: "createdAt",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const result =
    data &&
    data?.map((val, index) => ({
      id: index + 1,
      profile: (
        <Avatar
          src={baseUrlImage + val?.image}
          sx={{ width: 50, height: 50 }}
        />
      ),
      name: val?.name,
      shop_name: val?.shop_name,
      email: val?.email,
      phone: val?.phone,
      status: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={val?.status == 0 ? false : true}
                onChange={(event) =>
                  handleToggle(val._id, event.target.checked)
                }
                color="secondary"
                name="toggleButton"
              />
            }
          />
        </Box>
      ),
      admin_due: val?.admin_due || 0,
      wallet: val?.wallet || 0,
      createdAt: moment(val?.createdAt).format("LL"),
      action: (
        <Typography color="textSecondary" variant="h6">
          <Link to={`/restaurant/staff/update/${val?._id}`}>
            <Button component="a" variant="outlined" color="secondary">
              Update
            </Button>
          </Link>
          <Button
            sx={{
              marginLeft: "5px",
            }}
            variant="outlined"
            color="error"
            onClick={() => deleteUser(val._id)}
          >
            Delete
          </Button>
        </Typography>
      ),
    }));

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Staff"} link={"/restaurant/Staff/add"} />
        {isFetching ? (
          <Circles
            height="80"
            width="80"
            color="#6f42c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loader_cl "
            visible={true}
          />
        ) : (
          <>
            <MUIDataTable data={result} columns={columns} options={options} />
          </>
        )}
      </div>
    </div>
  );
};

export default Staff;
