import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import BaseCard from "../../components/baseCard";
import { getShop, profileEdit } from "../../../redux/feature/authSlice";
import { baseUrlImage } from "../../../utils/config";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import toast from "react-hot-toast";

const validationSchema = yup.object({
  name: yup.string("Enter your Name").required("Name is required"),
  email: yup.string("Enter Email").email().required("Email type is required"),
  phone: yup.string("Enter Phone No.").required("Phone no. is required"),
  shop_name: yup.string("Enter Shop Name.").required("Shop name is required"),
  city: yup.string("Enter City").required("City is required"),
  // address: yup.string("Enter Address.").required("Address is required"),
  description: yup
    .string("Enter Description")
    .required("Description is required"),
});

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [shop, setShop] = useState(null);
  const { auth, token } = useSelector((state) => state.auth);

  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [address, setAddress] = useState("");

  const handleSelect = async (selected) => {
    const results = await geocodeByAddress(selected);
    const latLng = await getLatLng(results[0]);
    // console.log("Selected Place: ", results[0]?.formatted_address);
    // console.log("Latitude and Longitude: ", latLng);
    setLat(latLng?.lat);
    setLong(latLng?.lng);
    setAddress(results[0]?.formatted_address);
  };

  useEffect(() => {
    dispatch(getShop(token)).then((value) => {
      setShop(value?.payload?.data);
    });
  }, [dispatch, token]);

  const formik = useFormik({
    initialValues: {
      name: auth.name || "",
      email: auth.email || "",
      phone: auth.phone || "",
      address: shop?.address || "",
      city: shop?.city || "",
      shop_name: shop?.shop_name || "",
      description: shop?.description || "",
      image: "",
      shop_image: shop?.shop_image || [],
      lat: "",
      long: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
      const myForm = new FormData();
      if (lat) {
        myForm.set("address", address);
        myForm.set("lat", lat);
        myForm.set("long", long);
      } 
      myForm.set("name", values.name);
      myForm.set("phone", values.phone);
      myForm.set("image", values.image ? values.image : auth.image);
      // myForm.append("shop_image", values.shop_image);
      myForm.set("shop_name", values.shop_name);
      myForm.set("city", values.city);
      myForm.set("description", values.description);
      myForm.set("type", 1);
      values.shop_image?.forEach((image) => {
        if (image !== '') {
          myForm.append("shop_image", image);
        }
    });
      dispatch(profileEdit({ myForm, token }));
      navigate(-1);
    },
  });

  const handleCoverImageChange = (e) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      setFile(e.target.result);
    };
    formik.setFieldValue("image", e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleCoverShopImageChange = (e) => {
    const files = Array.from(e.target.files);
    const uploadedImages = files.map((file) => file);
    formik.setFieldValue("shop_image", uploadedImages);
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Profile Setting"} />
          <div class="row justify-content-center">
            <div class="card">
              <div class="card-body">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <Stack spacing={3} sx={{ padding: "30px" }}>
                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "50%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="name"
                          name="name"
                          label="Enter Name"
                          variant="outlined"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                        />
                      </Box>
                      <Box
                        sx={{ margin: "auto", width: "50%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Enter Email"
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          disabled
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "100%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="phone"
                          name="phone"
                          label="Enter Phone no."
                          variant="outlined"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "100%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="shop_name"
                          name="shop_name"
                          label="Shop Name"
                          variant="outlined"
                          value={formik.values.shop_name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.shop_name &&
                            Boolean(formik.errors.shop_name)
                          }
                          helperText={
                            formik.touched.shop_name && formik.errors.shop_name
                          }
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "100%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="description"
                          name="description"
                          label="Shop Description"
                          variant="outlined"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.description &&
                            Boolean(formik.errors.description)
                          }
                          helperText={
                            formik.touched.description &&
                            formik.errors.description
                          }
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "100%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="city"
                          name="city"
                          label="City"
                          variant="outlined"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.city && Boolean(formik.errors.city)
                          }
                          helperText={formik.touched.city && formik.errors.city}
                        />
                      </Box>
                    </Box>
                    <div className="form-group col-md-6 mt-4">
                      <label for="phone">Change Address</label>
                      <br />
                      <PlacesAutocomplete
                        value={address}
                        onChange={(value) => setAddress(value)}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: "Search Places...",
                                className: "location-search-input form-control",
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#41b6e6"
                                    : "#fff",
                                };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>

                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "100%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="addressNew"
                          name="addressNew"
                          variant="outlined"
                          value={shop?.address}
                          disabled
                        />
                      </Box>
                    </Box>

                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Box display="flex" flexWrap="wrap">
                          {file || auth?.image !== "" ? (
                            <Card
                              sx={{
                                maxWidth: 200,
                                maxHeight: 200,
                                margin: "8px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="140"
                                image={
                                  file ? file : baseUrlImage + "" + auth?.image
                                }
                                alt={`User image`}
                              />
                            </Card>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>

                      <Box>
                        <input
                          type="file"
                          onChange={handleCoverImageChange}
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-image-upload"
                        />
                        <label htmlFor="cover-image-upload">
                          <Button
                            variant="contained"
                            component="span"
                            color="secondary"
                          >
                            Image
                          </Button>
                        </label>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Box display="flex" flexWrap="wrap">
                          {formik.values?.shop_image?.map((imageUrl, index) => (
                            <Card
                              key={index}
                              sx={{
                                maxWidth: 200,
                                maxHeight: 200,
                                margin: "8px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="140"
                                image={imageUrl?._id ? baseUrlImage + "/" + imageUrl?.name : URL.createObjectURL(imageUrl)}
                                alt={`shop image`}
                              />
                            </Card>
                          ))}
                        </Box>
                      </Box>

                      <Box>
                        <input
                          type="file"
                          multiple
                          onChange={handleCoverShopImageChange}
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-image-upload-shop"
                        />
                        <label htmlFor="cover-image-upload-shop">
                          <Button
                            variant="contained"
                            component="span"
                            color="secondary"
                          >
                            Shop Image
                          </Button>
                        </label>
                      </Box>
                    </Grid>

                    <Box display="flex" sx={{ justifyContent: "center" }}>
                      <Box sx={{ mt: 4 }}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Update
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Stack>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
