import React, { useState } from "react";
import BaseCard from "../../components/baseCard";
import { Box, Button, Card, CardMedia, Grid } from "@mui/material";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddShopkeeper } from "../../../redux/feature/userSlice";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const validationSchema = yup.object().shape({
  name: yup.string("Enter your name").required("Name is required"),
  email: yup
    .string("Enter your email or username")
    .required("Email or username is required"),
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  phone: yup.string("Enter your phone").required("Phone no. is required"),
});

const Add = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [fileShop, setFileShop] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [address, setAddress] = useState("");

  const handleSelect = async (selected) => {
    const results = await geocodeByAddress(selected);
    const latLng = await getLatLng(results[0]);
    console.log("Selected Place: ", results[0]?.formatted_address);
    console.log("Latitude and Longitude: ", latLng);
    setLat(latLng?.lat);
    setLong(latLng?.lng);
    setAddress(results[0]?.formatted_address);
  };


  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      phone: "",
      shop_name: "",
      shop_image: "",
      address: "",
      city: "",
      lat: "",
      long: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values, "valuesvalues");
      const myForm = new FormData();
      myForm.set("name", values.name);
      myForm.set("phone", values.phone);
      myForm.set("email", values.email);
      myForm.set("password", values.password);
      myForm.set("shop_name", values.shop_name);
      myForm.set("shop_image", values.shop_image);
      myForm.set("city", values.city);
      myForm.set("address", address);
      myForm.set("lat", lat);
      myForm.set("long", long);

      dispatch(AddShopkeeper({ myForm, token }));
      navigate(-1);
    },
  });

  const handleCoverShopImageChange = (e) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      setFileShop(e.target.result);
    };
    formik.setFieldValue("shop_image", e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Shopkeeper / Add"} />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group form-group col-md-6">
                    <label for="name">Full name</label>
                    <FormGroup controlId="name" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter full name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.name && formik.errors.name}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div class="form-group form-group col-md-6">
                    <label for="email">Email</label>
                    <FormGroup controlId="email" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.email && formik.errors.email}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="phone">Phone No.</label>
                    <FormGroup controlId="phone" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter phone no."
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.phone && formik.errors.phone}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="phone">Password</label>
                    <FormGroup
                      controlId="password"
                      className="position-relative"
                    >
                      <FormControl
                        type={"password"}
                        className="form-control"
                        placeholder="Enter password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.password && formik.errors.password}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <h4>Shop Details</h4>
                  <div className="form-group col-md-6 mt-4">
                    <label for="phone">Shop Name</label>
                    <FormGroup
                      controlId="shop_name"
                      className="position-relative"
                    >
                      <FormControl
                        type={"shop_name"}
                        className="form-control"
                        placeholder="Enter Shop Name"
                        value={formik.values.shop_name}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.shop_name &&
                          Boolean(formik.errors.shop_name)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.shop_name && formik.errors.shop_name}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <div className="form-group col-md-6 mt-4">
                    <label for="phone">Address</label>
                    <br />
                    <PlacesAutocomplete
                      value={address}
                      onChange={(value) => setAddress(value)}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Search Places...",
                              className: "location-search-input form-control",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41b6e6"
                                  : "#fff",
                              };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>

                  <div className="form-group col-md-6">
                    <label for="phone">City</label>
                    <FormGroup controlId="city" className="position-relative">
                      <FormControl
                        type={"city"}
                        className="form-control"
                        placeholder="Enter city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.city && formik.errors.city}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div className="form-group col-md-6">
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Box display="flex" flexWrap="wrap">
                          {fileShop && (
                            <Card
                              sx={{
                                maxWidth: 200,
                                maxHeight: 200,
                                margin: "8px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="140"
                                image={fileShop}
                                alt={`shop image`}
                              />
                            </Card>
                          )}
                        </Box>
                      </Box>

                      <Box>
                        <input
                          type="file"
                          onChange={handleCoverShopImageChange}
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-image-upload-shop"
                        />
                        <label htmlFor="cover-image-upload-shop">
                          <Button
                            variant="contained"
                            component="span"
                            color="secondary"
                          >
                            Shop Image
                          </Button>
                        </label>
                      </Box>
                    </Grid>
                  </div>
                </div>

                <button type="submit" class="btn btn-gradient-primary me-2">
                  Submit
                </button>
                <Link to="/admin/shopkeeper" class="btn btn-light">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
