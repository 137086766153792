import React, { useEffect } from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCategory,
  getCategory,
  statusCategory,
} from "../../../redux/feature/CategorySlice";
import Swal from "sweetalert2";
import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  Button,
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import { baseUrlImage } from "../../../utils/config";

const Index = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { category, loading, isSuccessful } = useSelector(
    (state) => state.category
  );

  useEffect(() => {
    dispatch(getCategory({ token }));
  }, [dispatch, token, isSuccessful]);

  const deleteUser = (id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCategory({ id: id, token }));
      }
    });
  };

  const handleToggle = (id, checked) => {
    let myForm = {
      id,
      status: checked === false ? 0 : 1,
    };
    dispatch(statusCategory({ myForm, token }));
  };

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
    // rowsPerPage: rowsPerPage,
    // rowsPerPageOptions: [10, 25, 50, 100],
    // serverSide: true,
    // page: page,
    // count: classes?.totalData,
    // onSearchChange: (searchText) => handleSearch(searchText),
    // searchText: searchText,
    // onChangePage: handleChangePage,
    // onChangeRowsPerPage: handleChangeRowsPerPage,
  };
  const columns = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "Title",
      name: "title",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Image",
      name: "image",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Created At",
      name: "createdAt",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const result =
    category &&
    category?.map((val, index) => ({
      id: index + 1,
      title: val?.title,
      image: <Avatar src={baseUrlImage + val?.image} width={50} height={50} />,
      status: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={val?.status == 0 ? false : true}
                onChange={(event) =>
                  handleToggle(val._id, event.target.checked)
                }
                color="secondary"
                name="toggleButton"
              />
            }
          />
        </Box>
      ),
      createdAt: moment(val?.createdAt).format("LL"),
      action: (
        <Typography color="textSecondary" variant="h6">
          <Link to={`/admin/category/update/${val?._id}`}>
            <Button component="a" variant="outlined" color="secondary">
              Update
            </Button>
          </Link>
          <Button
            sx={{
              marginLeft: "5px",
            }}
            variant="outlined"
            color="error"
            onClick={() => deleteUser(val._id)}
          >
            Delete
          </Button>
        </Typography>
      ),
    }));

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Categories"} link="/admin/category/add" />
        {loading ? (
          <Circles
            height="80"
            width="80"
            color="#6f42c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loader_cl"
            visible={true}
          />
        ) : (
          <>
            <MUIDataTable data={result} columns={columns} options={options} />
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
