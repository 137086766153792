import React, { useState } from "react";
import { Card, Form, FormControl, FormGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBank } from "../../../redux/feature/bankSlice";
import Modal from "react-bootstrap/Modal";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import { CardMedia } from "@mui/material";
import { Grid } from "@mui/material";
import { updateWithDraw } from "../../../redux/feature/withdrawSlice";

const validationSchema = yup.object().shape({
  transaction_id: yup
    .string("Select transaction_id")
    .required("Transaction Id is a required field"),
});

const Add = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const show = props.show;
  const onClose = props.onClose;
  const [file, setFile] = useState(null);
  const { token } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      transaction_id: "",
      image: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const myForm = new FormData();
      myForm.set("transaction_id", values.transaction_id);
      myForm.set("id", props.Id);
      myForm.set("image", values.image);
      dispatch(updateWithDraw({ myForm, token })).then((value) => {
        navigate(`/admin/withdraw/view/${props.Id}`);
      });
    },
  });

  const handleCoverImageChange = (e) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      setFile(e.target.result);
    };
    formik.setFieldValue("image", e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Accept Withdrawal Request</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "80vh", overflowY: "auto" }}>
        <div className="d-flex justify-content-center align-items-center">
          <form
            onSubmit={formik.handleSubmit}
            className="forms-sample text-center p-4 rounded"
          >
            <div className="mb-3">
              <label for="transaction_id">Transaction Id</label>
              <FormGroup
                controlId="transaction_id"
                className="position-relative"
              >
                <FormControl
                  type={"text"}
                  className="form-control"
                  placeholder="Enter Transaction Id"
                  value={formik.values.transaction_id}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.transaction_id &&
                    Boolean(formik.errors.transaction_id)
                  }
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {formik.touched.transaction_id &&
                    formik.errors.transaction_id}
                </Form.Control.Feedback>
              </FormGroup>
            </div>

            <Grid item xs={12}>
              <Box mb={2}>
                <Box display="flex" flexWrap="wrap">
                  {file && (
                    <Card
                      sx={{
                        maxWidth: 200,
                        maxHeight: 200,
                        margin: "8px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="140"
                        image={file}
                        alt={`File Cover`}
                      />
                    </Card>
                  )}
                </Box>
              </Box>

              <Box>
                <input
                  type="file"
                  onChange={handleCoverImageChange}
                  accept="image/*"
                  style={{ display: "none" }}
                  id="cover-image-upload"
                />
                <label htmlFor="cover-image-upload">
                  <Button
                    variant="contained"
                    component="span"
                    color="secondary"
                  >
                    File
                  </Button>
                </label>
              </Box>
            </Grid>
            <Button
              type="submit"
              className="btn btn-gradient-primary me-2 mt-3"
            >
              Submit
            </Button>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Add;
