import React, { useRef, useState } from "react";
import BaseCard from "../../components/baseCard";
import { Form, FormControl, FormGroup, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddTable } from "../../../redux/feature/tableSlice";
import QRCodeWithLogo from "../../components/qr-code/QRCodeWithLogo";
import { baseUrlImage } from "../../../utils/config";
import { toPng } from "html-to-image";
import Button from "@mui/material/Button";

const validationSchema = yup.object().shape({
  title: yup
    .number("Select Table number")
    .required("Table number is a required field"),
});

const Add = () => {
  const navigate = useNavigate();
  const htmlElementRef = useRef(null);
  const dispatch = useDispatch();
  const { token, auth } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      toPng(htmlElementRef.current)
        .then((dataUrl) => {
          console.log(dataUrl ,"pppp")
          values.image = dataUrl;
          dispatch(AddTable({ values, token }));
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error converting HTML to image:", error);
        });
    },
  });

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Table / Add"} />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group form-group">
                    <label for="title">Title</label>
                    <FormGroup controlId="title" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.title && formik.errors.title}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  {formik.values.title.trim() && (
                    <div
                      className="row justify-content-center"
                      ref={htmlElementRef}
                    >
                      <Card border="warning" style={{ width: "18rem" }}>
                        <Card.Body className="d-flex flex-column align-items-center">
                          <QRCodeWithLogo
                            value={[formik.values.title ,auth?._id]}
                            logoSrc={"/assets/images/newLogo.png"} // Provide the path to your logo
                            logoSize={200} // Adjust the size of the logo as needed
                          />
                          <Button
                            component="label"
                            sx={{
                              backgroundColor: "#000",
                              color: "#fff",
                              marginTop: "10px",
                            }}
                          >
                            Table {formik.values.title}
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  class="btn btn-gradient-primary me-2 mt-5"
                >
                  Submit
                </button>
                <Link to="/restaurant/table" class="btn btn-light mt-5">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
