import React from "react";
import { Link } from "react-router-dom";

const BaseCard = (props) => {
  return (
    <>
      <div className="page-header">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i className="mdi mdi-home"></i>
          </span>{" "}
          {props.title}
        </h3>
        {props.link && (
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                <Link to={props.link} className="btn bg-gradient-primary">
                {props.name || "Add"}
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </>
  );
};

export default BaseCard;
