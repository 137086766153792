import React from "react";
import BaseCard from "../../components/baseCard";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddBank } from "../../../redux/feature/bankSlice";

const validationSchema = yup.object().shape({
  accountHolder: yup
    .string("Enter your Account holder")
    .required("Account holder is a required field"),
  bankName: yup
    .string("Select Bank name")
    .required("Bank name is a required field"),
  accountNumber: yup.number().required().positive().integer().test('Must be exactly 20 characters', val => val && (val.toString().length >= 7 && val.toString().length <= 20) ),
  swiftCode: yup.number().required().positive().integer().test('Must be exactly 15 characters', val => val && (val.toString().length >= 5 && val.toString().length <= 15) ),
});

const Add = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      accountHolder: "",
      bankName: "",
      accountNumber: "",
      swiftCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(AddBank({ values, token }));
      navigate(-1);
    },
  });

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Bank Details / Add"} />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                 <div class="form-group form-group col-md-6">
                    <label for="accountHolder">Title</label>
                    <FormGroup controlId="accountHolder" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter Account Holder"
                        value={formik.values.accountHolder}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.accountHolder && Boolean(formik.errors.accountHolder)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.accountHolder && formik.errors.accountHolder}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                 <div class="form-group form-group col-md-6">
                    <label for="bankName">Bank Name</label>
                    <FormGroup controlId="bankName" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter Bank Name"
                        value={formik.values.bankName}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.bankName && Boolean(formik.errors.bankName)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.bankName && formik.errors.bankName}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                 <div class="form-group form-group col-md-6">
                    <label for="accountNumber">Account Number</label>
                    <FormGroup controlId="accountNumber" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter Account Number"
                        value={formik.values.accountNumber}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.accountNumber && Boolean(formik.errors.accountNumber)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.accountNumber && formik.errors.accountNumber}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div class="form-group form-group col-md-6">
                    <label for="swiftCode">Swift Code</label>
                    <FormGroup controlId="swiftCode" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter Swift Code"
                        value={formik.values.swiftCode}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.swiftCode && Boolean(formik.errors.swiftCode)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.swiftCode && formik.errors.swiftCode}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                </div>
                <button
                  type="submit"
                  class="btn btn-gradient-primary me-2 mt-5"
                >
                  Submit
                </button>
                <Link to="/restaurant/bank" class="btn btn-light mt-5">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
