import React, { useEffect } from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getBank, deleteBank } from "../../../redux/feature/bankSlice";
import moment from "moment";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import Swal from "sweetalert2";


const Index = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { bank, loading, isSuccessful } = useSelector((state) => state.bank);

  useEffect(() => {
    dispatch(getBank(token));
  }, [dispatch, token, isSuccessful]);

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    serverSide: true,
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteBank({ id: id, token }));
      }
    });
  };

  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Account Holder",
      name: "accountHolder",
    },
    {
      label: "Bank Name",
      name: "bankName",
    },
    {
      label: "Account Number",
      name: "accountNumber",
    },
    {
      label: "Swift Code",
      name: "swiftCode",
    },
    {
      label: "CreatedAt",
      name: "createdAt",
    },
    {
      label: "Action",
      name: "action",
    },
  ];

  const result =
    bank &&
    bank?.map((val, index) => ({
      id: index + 1,
      accountHolder: val?.accountHolder,
      accountNumber: val?.accountNumber,
      bankName: val?.bankName,
      swiftCode: val?.swiftCode,
      createdAt: moment(val?.createdAt).format("LL"),
      action: (
        <Typography color="textSecondary" variant="h6">
          <Button
            sx={{
              marginLeft: "5px",
            }}
            variant="outlined"
            color="error"
            onClick={() => deleteUser(val._id)}
          >
            Delete
          </Button>
        </Typography>
      ),
    }));

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Bank Details"} link={"/restaurant/bank/add"} />
        {loading ? (
          <Circles
            height="80"
            width="80"
            color="#6f42c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loader_cl"
            visible={true}
          />
        ) : (
          <>
            <MUIDataTable data={result} columns={columns} options={options} />
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
