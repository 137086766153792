import React, { useEffect, useState } from "react";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import BaseCard from "../../components/baseCard";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { UpdateCoupon, viewCoupon } from "../../../redux/feature/CouponSlice";
import moment from "moment";

const validationSchema = yup.object().shape({
  title: yup.string("Enter your title").required(),
  points: yup.number("Enter your points").required(),
  // validity: yup.date("Enter your validity").required(),
  discount: yup.number("Enter your discount").max(100).required(),
});

const Update = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [dateVal, setDate] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const { singleCoupon } = useSelector((state) => state.coupon);

  useEffect(() => {
    dispatch(viewCoupon({ id, token }));
  }, [dispatch, token, id]);

  const formik = useFormik({
    initialValues: {
      title: singleCoupon?.title || "",
      points: singleCoupon?.points || "",
      // validity: singleCoupon?.validity || "",
      discount: singleCoupon?.discount || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.id = singleCoupon._id;
      // values.validity = dateVal
      //   ? moment(dateVal?._d).format("YYYY-MM-DD")
      //   : null;
      dispatch(UpdateCoupon({ values, token }));
      navigate(-1);
    },
  });

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Coupon / Update"} link="/restaurant/Coupon" name="Back" />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group form-group col-md-6">
                    <label for="title">Title</label>
                    <FormGroup controlId="title" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.title && formik.errors.title}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  {/* <div class="form-group form-group col-md-6">
                    <label for="validity">Validity</label>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        fullWidth
                        value={moment.utc(singleCoupon?.validity)}
                        onChange={(date) => {
                          setDate(date);
                          formik.setFieldValue("validity", date); // Update Formik field
                        }}
                        disablePast
                      />
                       {formik.touched.validity && (
                        <span class="badge bg-danger p-3">
                          {formik.touched.validity && formik.errors.validity}
                        </span>
                      )}
                    </DemoContainer>
                  </div> */}

                  <div class="form-group form-group col-md-6">
                    <label for="points">Points</label>
                    <FormGroup controlId="points" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter points"
                        value={formik.values.points}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.points && Boolean(formik.errors.points)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.points && formik.errors.points}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <div class="form-group form-group col-md-6">
                    <label for="discount">Discount %</label>
                    <FormGroup
                      controlId="discount"
                      className="position-relative"
                    >
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter discount"
                        value={formik.values.discount}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.discount &&
                          Boolean(formik.errors.discount)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.discount && formik.errors.discount}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                </div>
                <button type="submit" class="btn btn-gradient-primary me-2">
                  Submit
                </button>
                <Link to="/restaurant/coupon" class="btn btn-light">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
