import React, { useEffect } from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BaseCard from "../../components/baseCard";
import { baseUrlImage } from "../../../utils/config";
import { viewWithdraw } from "../../../redux/feature/withdrawSlice";

const View = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { withdrawSingle } = useSelector((state) => state.withdraw);

  useEffect(() => {
    dispatch(viewWithdraw({ id, token }));
  }, [dispatch, id, token]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard
            title={"Withdrawal /view"}
            link="/admin/withdraw"
            name="Back"
          />
          <Card className="mt-5">
            <CardContent>
              <div className="row w-100 gx-4 gy-5">
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Bank Name:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {withdrawSingle?.bank_id?.bankName}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Account Holder:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {withdrawSingle?.bank_id?.accountHolder}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Account Number:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {withdrawSingle?.bank_id?.accountNumber}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                  Swift Code:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {withdrawSingle?.bank_id?.swiftCode}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Amount:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {withdrawSingle?.amount}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Status:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {withdrawSingle?.status === 1 ? (
                   <Button className="btn bg-gradient-primary">Paid</Button>
                    ) : withdrawSingle?.status === 2 ? (
                      <Button variant="contained" color="warning">
                        Rejected
                      </Button>
                    ) : (
                      <Button variant="contained" color="warning">
                        Pending
                      </Button>
                    )}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Created At:
                  </Typography>
                  <Typography variant="body2">
                    {moment(withdrawSingle?.createdAt).format("LL")}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
          {withdrawSingle?.remark && (
          <Card className="mt-5">
            <CardContent>
              <div className="col-md-4">
                <Typography className="w-100 mb-1" variant="body1">
                  Remark:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {withdrawSingle?.remark?.replace(/(<([^>]+)>)/gi, "")}
                </Typography>
              </div>
            </CardContent>
          </Card>
            )}
          {withdrawSingle?.image && (
            <Card className="mt-5">
              <CardContent>
                <Typography className="w-100 mb-1" variant="body1">
                  Images:
                </Typography>
                <Box>
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={baseUrlImage + withdrawSingle?.image}
                      width="200"
                      height="200"
                      alt="dfh"
                      className="col-md-2 p-1"
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};

export default View;
