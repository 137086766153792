import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Container,
  Typography,
  Button,
  Box,
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { viewUser } from "../../../redux/feature/userSlice";
import BaseCard from "../../components/baseCard";
import { Avatar } from "@mui/material";
import { baseUrlImage } from "../../../utils/config";

const ViewUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { singleUser } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(viewUser({ id, token }));
  }, [dispatch, id, token]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Users/view"} link="/admin/user" name="Back" />
          <Card className="mt-5">
            <CardContent>
              <div className="row w-100 gx-4 gy-5">
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Profile Image:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <Avatar
                      src={baseUrlImage + singleUser?.image}
                      sx={{ width: 100, height: 100 }}
                    />
                  </Typography>
                </div>

                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    First Name:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.name}
                  </Typography>
                </div>

                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Last Name:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.last_name}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Email:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.email}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Phone:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.phone}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Reward Points:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.reward}
                  </Typography>
                </div>

                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Status:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.status == 1 ? (
                      <Button variant="contained" color="success">
                        Active
                      </Button>
                    ) : (
                      <Button variant="contained" color="warning">
                        InActive
                      </Button>
                    )}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Created At:
                  </Typography>
                  <Typography variant="body2">
                    {moment(singleUser?.createdAt).format("LL")}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ViewUser;
