import React from "react";
import { QRCodeSVG } from "qrcode.react";

const QRCodeWithLogo = ({ value, logoSrc, logoSize = 200 }) => {
  return (
    <QRCodeSVG
      value={value}
      size={logoSize}
      includeMargin
      level={"H"}
      imageSettings={{
        src: logoSrc,
        x: undefined,
        y: undefined,
        height: 60,
        width: 60,
        excavate: true,
      }}
    />
  );
};

export default QRCodeWithLogo;
