import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ dataInfo }) => {
  const data = {
    labels: ["Menu", "Category", "Transaction", "Feed", "Orders"],
    datasets: [
      {
        label: "Data",
        data: [dataInfo?.totalMenu, dataInfo?.totalCategory, dataInfo?.totalTransaction, dataInfo?.totalFeed, dataInfo?.totalOrderAll],
        backgroundColor: [
          "rgba(255 191 0)",
          "rgba(32, 101, 209)",
          "rgba(160 32 240)",
          "rgba(190 38 54)",
          "rgba(15, 228, 212)",
        ],
        borderColor: [
          "rgba(255, 191, 0, 1)",
          "rgba(35, 101, 208, 1)",
          "rgba(160, 40, 240, 1)",
          "rgba(240, 38, 54, 1)",
          "rgba(15, 228, 212, 0.8)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return <Pie data={data} />;
};

export default PieChart;
