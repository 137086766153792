import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Button, Avatar ,Box ,FormControlLabel, Switch} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { viewUser } from "../../../redux/feature/userSlice";
import BaseCard from "../../components/baseCard";
import { baseUrlImage } from "../../../utils/config";
import axios from "axios";
import fileDownload from "js-file-download";
import { API_DOWNLOAD_QR } from "../../../utils/APIConstant";
import { getTableAll } from "../../../redux/feature/tableSlice";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import MUIDataTable from "mui-datatables";

const ViewShopkeeper = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { singleUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(viewUser({ id, token }));
    dispatch(getTableAll({ id, token })).then((val) => {
      if (val.payload.success) setData(val.payload?.data);
    });
  }, [dispatch, id, token]);

  const downloadFile = async (name) => {
    await axios
      .get(API_DOWNLOAD_QR + "/" + name, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        fileDownload(res.data, "qr_code.jpeg");
      });
  };

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    serverSide: true,
  };

  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Table no.",
      name: "table",
    },
    {
      label: "Qr Code",
      name: "qr_code",
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "CreatedAt",
      name: "createdAt",
    },
  ];

  const result =
  data &&
  data?.map((val, index) => ({
      id: index + 1,
      table: val?.title,
      qr_code : (
        <>
          <Avatar
            src={baseUrlImage + "/qrCode/" + val?.qrCode}
            sx={{ width: 50, height: 50 }}
          />
            <button
              onClick={() => downloadFile(val?.qrCode)}
              className="btn btn-success d-block mt-1"
            >
              {" "}
              <ArrowCircleDownIcon />
            </button>
        </>
      ),
      status: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={val?.status == 0 ? false : true}
                color="secondary"
                name="toggleButton"
              />
            }
          />
        </Box>
      ),
      createdAt: moment(val?.createdAt).format("LL"),
    }));

    


  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard
            title={"Shopkeeper/view"}
            link="/admin/shopkeeper"
            name="Back"
          />
          <Card className="mt-5">
            <CardContent>
              <div className="row w-100 gx-4 gy-5">
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Profile Image:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <Avatar
                      src={baseUrlImage + singleUser?.image}
                      sx={{ width: 100, height: 100 }}
                    />
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Full Name:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.name}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Email:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.email}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Phone:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.phone}
                  </Typography>
                </div>

                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Status:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.status == 1 ? (
                      <Button variant="contained" color="success">
                        Active
                      </Button>
                    ) : (
                      <Button variant="contained" color="warning">
                        InActive
                      </Button>
                    )}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Created At:
                  </Typography>
                  <Typography variant="body2">
                    {moment(singleUser?.createdAt).format("LL")}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="mt-5">
            <CardContent>
              <div className="row w-100 gx-4 gy-5">
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Shop Name:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.vendor?.shop_name}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Shop Address:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.vendor?.address}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Shop City:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleUser?.vendor?.city}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Shop Image:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <Avatar
                      src={baseUrlImage + singleUser?.shop_image}
                      sx={{ width: 100, height: 100 }}
                    />
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="mt-3">
            <CardContent>
              <Typography variant="body2" color="text.secondary">
              <MUIDataTable data={result} columns={columns} options={options} />
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ViewShopkeeper;
