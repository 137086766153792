const api = {
  // Manage Device
  staff_listing: "restaurant/staff-list",
  staff_add: "restaurant/add-staff",
  staff_edit: "restaurant/staff-edit",
  user_view: "user/view",
  staff_delete: "restaurant/delete",
  feed_add: "feed/add",
 
};

export { api };
