import React, { useEffect, useState } from "react";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import BaseCard from "../../components/baseCard";
import { useFormik } from "formik";
import * as yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  CardMedia,
  Card,
  Button,
  Typography,
  Chip,
} from "@mui/material";
import ReactQuill from "react-quill";
import { EditorModule } from "../../../utils/editor";
import { getCategory } from "../../../redux/feature/CategorySlice";
import {
  UpdateMenu,
  deleteFile,
  viewMenu,
} from "../../../redux/feature/menuSlice";
import { baseUrlImage } from "../../../utils/config";

const validationSchema = yup.object().shape({
  category_id: yup
    .string("Enter your Category")
    .required("Category is a required field"),
  title: yup.string("Select status").required("Status is a required field"),
  price: yup.number().required().positive().integer(),
  description: yup
    .string("Select description")
    .required("Description is a required field"),
});

const Update = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { token } = useSelector((state) => state.auth);
  const { category } = useSelector((state) => state.category);
  const { singleMenu, isSuccessful } = useSelector((state) => state.menu);
  const [imagesFile, setImages] = useState([]);

  useEffect(() => {
    dispatch(viewMenu({ id, token }));
    dispatch(getCategory({ token }));
  }, [dispatch, token, isSuccessful, id]);

  const formik = useFormik({
    initialValues: {
      category_id: singleMenu.category_id?._id || "",
      title: singleMenu?.title || "",
      price: singleMenu?.price || "",
      description: singleMenu?.description || "",
      id: singleMenu?._id || "",
      images: [],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const myForm = new FormData();
      myForm.set("id", values?.id);
      myForm.set("category_id", values.category_id);
      myForm.set("title", values.title);
      myForm.set("price", values.price);
      myForm.set("description", values.description);
      values.images.forEach((image) => {
        myForm.append("images", image);
      });
      dispatch(UpdateMenu({ myForm, token }));
      navigate(-1);
    },
  });

  const handleDescription = (e) => {
    formik.setFieldValue("description", e);
  };
  const handleMenuImagesChange = (e) => {
    const ImageFIles = [];
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      ImageFIles.push(file);
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
    formik.setFieldValue("images", ImageFIles);
  };

  const handleDelete = (id) => {
    let myForm = {
      id,
      menu_id: singleMenu?._id,
    };

    dispatch(deleteFile({ myForm, token }));
    console.info(id, "You clicked the delete icon.");
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Menu / Update"} link="/admin/menu" name="Back" />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group form-group">
                    <label for="title">Title</label>
                    <FormGroup controlId="title" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.title && formik.errors.title}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div class="form-group form-group col-md-6">
                    <label for="price">Price</label>
                    <FormGroup controlId="price" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter price"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.price && Boolean(formik.errors.price)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.price && formik.errors.price}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="exampleInputUsername1">Category</label>
                    <FormGroup
                      controlId="category_id"
                      className="position-relative"
                    >
                      <FormControl
                        as="select"
                        name="category_id"
                        value={formik.values.category_id}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.category_id &&
                          Boolean(formik.errors.category_id)
                        }
                      >
                        <option>Select</option>
                        {category &&
                          category?.map((val, index) => (
                            <option value={val?._id} key={index}>
                              {val?.title}
                            </option>
                          ))}
                      </FormControl>
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.category_id &&
                          formik.errors.category_id}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <div class="form-group form-group">
                    <ReactQuill
                      modules={EditorModule}
                      theme="snow"
                      onChange={(e) => handleDescription(e)}
                      defaultValue={singleMenu && singleMenu?.description}
                      placeholder="Content goes here..."
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <Typography variant="caption" color="error">
                          {formik.errors.description}
                        </Typography>
                      )}
                  </div>

                  <Grid item xs={6}>
                    <Box mb={2}>
                      <Box display="flex" flexWrap="wrap">
                        {imagesFile?.map((imageUrl, index) => (
                          <Card
                            sx={{
                              maxWidth: 200,
                              maxHeight: 200,
                              margin: "8px",
                            }}
                            key={index}
                          >
                            <CardMedia
                              component="img"
                              height="140"
                              image={imageUrl}
                              alt={`Front side Image`}
                            />
                          </Card>
                        ))}

                        {singleMenu?.image?.map((url, index) => (
                          <Card
                            key={index}
                            sx={{
                              maxWidth: 200,
                              maxHeight: 200,
                              margin: "8px",
                            }}
                          >
                            <Chip
                              dir="ltr"
                              onDelete={() => handleDelete(url._id)}
                              deleteIcon={<DeleteIcon />}
                            />
                            <CardMedia
                              component="img"
                              height="140"
                              image={baseUrlImage + url.name}
                              alt={`Menu images front ${index + 1}`}
                            />
                          </Card>
                        ))}
                      </Box>
                    </Box>

                    <Box>
                      <input
                        type="file"
                        multiple
                        name="images"
                        onChange={handleMenuImagesChange}
                        accept="image/*"
                        style={{ display: "none" }}
                        id="imagesFront-image-upload"
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.images && formik.errors.images}
                      </Form.Control.Feedback>
                      <label htmlFor="imagesFront-image-upload">
                        <Button
                          variant="contained"
                          component="span"
                          color="secondary"
                        >
                          Add Image
                        </Button>
                      </label>
                    </Box>
                  </Grid>
                </div>
                <button
                  type="submit"
                  class="btn btn-gradient-primary me-2 mt-5"
                >
                  Submit
                </button>
                <Link to="/admin/menu" class="btn btn-light mt-5">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
