import React, { useEffect, useState } from "react";
import BaseCard from "../../components/baseCard";
import MUIDataTable from "mui-datatables";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getTransaction } from "../../../redux/feature/orderSlice";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import moment from "moment";

const Index = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getTransaction(token)).then((value) => {
      if (value.payload.code === 200) {
        setData(value.payload.data);
      }
      setLoading(false);
    });
  }, [dispatch, token]);

  const options = {
    filter: true,
    search: true,
    sort: true,
    selectableRows: "none",
    pagination: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    serverSide: true,
  };
  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Order No.",
      name: "order_id",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Net Paid",
      name: "price",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Tax",
      name: "tax",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Total Amount",
      name: "amount",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Reward",
      name: "reward",
    },
    {
      label: "Admin commission",
      name: "admin_commission",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      label: "Payment Type.",
      name: "payment_type",
    },
    {
      label: "Payment Status",
      name: "payment_status",
    },
    {
      label: "Created At",
      name: "createdAt",
    },
  ];

  const result =
    data &&
    data?.map((val, index) => ({
      id: index + 1,
      order_id: (
        <Link to={`/admin/order/view/${val?.order_id?._id}`}>
          <Button color="secondary">{val?.order_id?.order_id}</Button>
        </Link>
      ),
      tax: val?.tax,
      price: val?.price,
      amount: val?.total_amount,
      reward: val?.coupon && val?.coupon[0]?.amount,
      admin_commission: val?.admin_commission,
      // admin: (
      //   <Box
      //     sx={{
      //       display: "flex",
      //       alignItems: "center",
      //       justifyContent: "center",
      //     }}
      //   >
      //     {val?.admin_paid === 1 ? (
      //       <p>Paid</p>
      //     ) : val?.admin_paid === 0 ? (
      //       <p>Not Paid</p>
      //     ) : (
      //       ""
      //     )}
      //   </Box>
      // ),
      payment_type: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {val?.payment_type === 0 ? (
            <p>Cash</p>
          ) : val?.payment_type === 1 ? (
            <p>Online</p>
          ) : (
            ""
          )}
        </Box>
      ),
      payment_status: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {val?.payment_status === 1 ? (
            <p>Successful</p>
          ) : val?.payment_status === 0 ? (
            <p>Failed</p>
          ) : (
            ""
          )}
        </Box>
      ),
      createdAt: moment(val?.createdAt).format("LL"),
    }));

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Transaction"} />
        {loading ? (
          <Circles
            height="80"
            width="80"
            color="#6f42c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loader_cl"
            visible={true}
          />
        ) : (
          <>
            <MUIDataTable data={result} columns={columns} options={options} />
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
