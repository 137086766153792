import React, { useEffect, useState } from "react";
import BaseCard from "../../components/baseCard";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import voucher_codes from "voucher-code-generator";
import { AddCoupon } from "../../../redux/feature/CouponSlice";
import { DatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import moment from "moment";

const validationSchema = yup.object().shape({
  title: yup.string("Enter your title").required(),
  code: yup
    .number("Enter your Rewards Code")
    .required("Rewards Code is required"),
  points: yup.number("Enter your points").required(),
  discount: yup.number("Enter your discount").max(100).required(),
  // validity: yup.date("Enter your validity").required(),
  status: yup.string("Select status").required("Status is a required field"),
});

const Add = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [codeGen, setCode] = useState("");
  const { token } = useSelector((state) => state.auth);
  const [dateVal, setDate] = useState(null);

  useEffect(() => {
    voucherCodeGen();
  }, []);

  async function voucherCodeGen() {
    let voucher = voucher_codes.generate({
      length: 6,
      count: 1,
      charset: "0123456789",
    });
    setCode(voucher);
  }

  const formik = useFormik({
    initialValues: {
      title: "",
      code: codeGen || "",
      points: "",
      // validity: "",
      status: "",
      discount: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      // values.validity = dateVal
      //   ? moment(dateVal?._d).format("YYYY-MM-DD")
      //   : null;
      values.code = values.code[0];
      dispatch(AddCoupon({ values, token }));
      navigate(-1);
    },
  });

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Coupon / Add"} />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group form-group col-md-6">
                    <label for="title">Title</label>
                    <FormGroup controlId="title" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.title && formik.errors.title}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <div class="form-group form-group col-md-6">
                    <label for="code">Rewards Code</label>
                    <FormGroup controlId="code" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter code"
                        value={formik.values.code}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.code && Boolean(formik.errors.code)
                        }
                        disabled
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.code && formik.errors.code}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  {/* <div class="form-group form-group col-md-6">
                    <label for="validity">Validity</label>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        fullWidth
                        value={dateVal}
                        onChange={(date) => {
                          setDate(date);
                          formik.setFieldValue("validity", date);
                        }}
                        disablePast
                      />

                      {formik.touched.validity && (
                        <span class="badge bg-danger p-3">
                          {formik.touched.validity && formik.errors.validity}
                        </span>
                      )}
                    </DemoContainer>
                  </div> */}

                  <div class="form-group form-group col-md-6">
                    <label for="points">Points</label>
                    <FormGroup controlId="points" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter points"
                        value={formik.values.points}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.points && Boolean(formik.errors.points)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.points && formik.errors.points}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <div class="form-group form-group col-md-6">
                    <label for="discount">Discount %</label>
                    <FormGroup
                      controlId="discount"
                      className="position-relative"
                    >
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter discount"
                        value={formik.values.discount}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.discount &&
                          Boolean(formik.errors.discount)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.discount && formik.errors.discount}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="exampleInputUsername1">Status</label>
                    <FormGroup controlId="status" className="position-relative">
                      <FormControl
                        as="select"
                        name="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.status && Boolean(formik.errors.status)
                        }
                      >
                        <option>Select</option>
                        <option value="0">Inactive</option>
                        <option value="1">Active</option>
                      </FormControl>
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.status && formik.errors.status}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                </div>
                <button type="submit" class="btn btn-gradient-primary me-2">
                  Submit
                </button>
                <Link to="/restaurant/coupon" class="btn btn-light">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
