import React, { useEffect, useState } from "react";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import BaseCard from "../../components/baseCard";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { UpdateCoupon, viewCoupon } from "../../../redux/feature/CouponSlice";
import moment from "moment";

const validationSchema = yup.object().shape({
  title: yup.string("Enter your title").required(),
  points: yup.number("Enter your points").required(),
  validity: yup.date("Enter your validity").required(),
});

const Update = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [dateVal, setDate] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const { singleCoupon } = useSelector((state) => state.coupon);

  useEffect(() => {
    dispatch(viewCoupon({ id, token }));
  }, [dispatch, token, id]);

  const formik = useFormik({
    initialValues: {
      title: singleCoupon?.title || "",
      points: singleCoupon?.points || "",
      validity: singleCoupon?.validity || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.id = singleCoupon._id;
      values.validity = dateVal ? moment(dateVal?._d).format("YYYY-MM-DD") : null
      dispatch(UpdateCoupon({ values, token }));
      navigate(-1);
    },
  });

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard
          title={"Feed / Update"}
          link="/admin/feed"
          name="Back"
        />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group form-group col-md-6">
                    <label for="title">Title</label>
                    <FormGroup controlId="title" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.title && formik.errors.title}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div class="form-group form-group col-md-6">
                    <label for="validity">Validity</label>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          fullWidth
                          value={moment.utc(singleCoupon?.validity)}
                          onChange={(date) => {
                            setDate(date);
                            formik.setFieldValue("validity", date); // Update Formik field
                          }}
                          // error={
                          //   formik.touched.validity &&
                          //   Boolean(formik.errors.validity)
                          // }
                          // helperText={
                          //   formik.touched.validity && formik.errors.validity
                          // }
                          disablePast
                        />
                         {/* <Box>
            {formik.touched.selectedDate1 && formik.errors.selectedDate1 && (
              <div>
                <Typography variant="caption" color="error">
                  {formik.errors.selectedDate1}
                </Typography>
              </div>
            )}

            {formik.touched.selectedDate2 && formik.errors.selectedDate2 && (
              <div>
                <Typography sx={{ mx: 50 }} variant="caption" color="error">
                  {formik.errors.selectedDate2}
                </Typography>
              </div>
            )}
          </Box> */}
                      </DemoContainer>

                    {/* <FormGroup
                      controlId="validity"
                      className="position-relative"
                    >
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter validity"
                        value={formik.values.validity}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.validity &&
                          Boolean(formik.errors.validity)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.validity && formik.errors.validity}
                      </Form.Control.Feedback>
                    </FormGroup> */}
                  </div>

                  <div class="form-group form-group col-md-6">
                    <label for="points">Points</label>
                    <FormGroup controlId="points" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter points"
                        value={formik.values.points}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.points && Boolean(formik.errors.points)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.points && formik.errors.points}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                </div>
                <button type="submit" class="btn btn-gradient-primary me-2">
                  Submit
                </button>
                <Link to="/admin/feed" class="btn btn-light">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
