import React, { useEffect } from "react";
import { Card, CardContent, Typography, Button, Avatar } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BaseCard from "../../components/baseCard";
import { viewReport } from "../../../redux/feature/reportSlice";
import { baseUrlImage } from "../../../utils/config";

const View = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { singleReport } = useSelector((state) => state.report);

  useEffect(() => {
    dispatch(viewReport({ id, token }));
  }, [dispatch, id, token]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Report/view"} link="/admin/report" name="Back" />
          <Card className="mt-5">
            <CardContent>
              <div className="row w-100 gx-4 gy-5">
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    User Name:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleReport?.user?.name}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Restaurant:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {singleReport?.vendor?.name}
                  </Typography>
                </div>
                {(singleReport?.feeds?.type === 2 || singleReport?.feeds?.type === 3) && (
                  <div className="col-md-4">
                    <Typography className="w-100 mb-1" variant="body1">
                      Image/video:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {singleReport?.feeds?.type === 2 ? (
                        <Avatar
                          src={baseUrlImage + "" + singleReport?.feeds?.image}
                          sx={{ width: 80, height: 80 }}
                        />
                      ) : (
                        <video width="100" height="100" controls>
                          <source
                            src={baseUrlImage + "" + singleReport?.feeds?.image}
                            type="video/mp4"
                          />
                        </video>
                      )}
                    </Typography>
                  </div>
                )}
                <div className="col-md-4">
                  <Typography className="w-100 mb-1" variant="body1">
                    Created At:
                  </Typography>
                  <Typography variant="body2">
                    {moment(singleReport?.createdAt).format("LL")}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="mt-5">
            <CardContent>
              <div className="col-md-4">
                <Typography className="w-100 mb-1" variant="body1">
                  Comment:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {singleReport?.feeds?.description}
                </Typography>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default View;
