import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_GET_USER,
  API_DELETE_USER,
  API_GET_USER_STATUS,
  API_GET_USER_VIEW,
  API_RESTAURANT_ADD,
  API_RESTAURANT_LIST,
} from "../../utils/APIConstant";
import toast from "react-hot-toast";

export const getUser = createAsyncThunk("get-user", async ({ role, token }) => {
  const res = await axios.request({
    method: "get",
    url: API_GET_USER + "/" + role,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const viewUser = createAsyncThunk("view-user", async ({ id, token }) => {
  const res = await axios.request({
    method: "get",
    url: API_GET_USER_VIEW + "/" + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const userStatus = createAsyncThunk(
  "status-user",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_GET_USER_STATUS,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const userDelete = createAsyncThunk(
  "user-delete",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_DELETE_USER + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const AddShopkeeper = createAsyncThunk(
  "add-shopkeeper",
  async ({ myForm, token }) => {
    try {
      const res = await axios.request({
        method: "Post",
        url: API_RESTAURANT_ADD,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        data: myForm,
      });
      toast.success(res.data.message);
      return res.data;
    } catch (err) {
      toast.error(err.response.data.error);
      return err.response.data;
    }
  }
);

const UserSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    error: null,
    user: [],
    singleUser: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.user = action.payload.data;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(viewUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewUser.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.singleUser = action.payload.data;
    });
    builder.addCase(viewUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(AddShopkeeper.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddShopkeeper.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(AddShopkeeper.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(userStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(userStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(userDelete.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(userDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default UserSlice.reducer;
