import React, { useState } from "react";
import BaseCard from "../../components/baseCard";
import { Box, Button, Card, CardMedia, Grid } from "@mui/material";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { Circles } from "react-loader-spinner";
import { useQuery } from "react-query";
import { editStaff, userView } from "../../../utils";
import { baseUrlImage } from "../../../utils/config";

const validationSchema = yup.object().shape({
  name: yup.string("Enter your name").required("Name is required"),
  phone: yup.string("Enter your phone").required("Phone no. is required"),
});

const Update = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const { data, isLoading , isError, error } = useQuery(["staff-view" ,id],async () => {
      const res = await userView(id);
      return res?.data?.data;
    },
    { retry: false }
  );

  const formik = useFormik({
    initialValues: {
      name: data?.name || "",
      phone: data?.phone || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const myForm = new FormData();
      myForm.set("id", data._id);
      myForm.set("name", values.name);
      myForm.set("phone", values.phone);
      myForm.set("image", values.image ? values.image : data?.image);
      await editStaff(myForm);
      navigate(-1);
    },
  });

  const handleCoverShopImageChange = (e) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      setFile(e.target.result);
    };
    formik.setFieldValue("image", e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Staff / Add"} />
        <div class="row justify-content-center">
        {isLoading && (
          <Circles
            height="80"
            width="80"
            color="#6f42c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass="loader_cl "
            visible={true}
          />
        )}
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group form-group col-md-6">
                    <label for="name">Full name</label>
                    <FormGroup controlId="name" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter full name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.name && formik.errors.name}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="phone">Phone No.</label>
                    <FormGroup controlId="phone" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter phone no."
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.phone && formik.errors.phone}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div className="form-group col-md-6">
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Box display="flex" flexWrap="wrap">
                          {(file || data?.image) && (
                            <Card
                              sx={{
                                maxWidth: 200,
                                maxHeight: 200,
                                margin: "8px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="140"
                                image={file ? file : baseUrlImage+data?.image}
                                alt={`profile image`}
                              />
                            </Card>
                          )}
                        </Box>
                      </Box>

                      <Box>
                        <input
                          type="file"
                          onChange={handleCoverShopImageChange}
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-image-upload-shop"
                        />
                        <label htmlFor="cover-image-upload-shop">
                          <Button
                            variant="contained"
                            component="span"
                            color="secondary"
                          >
                            Profile Image
                          </Button>
                        </label>
                      </Box>
                    </Grid>
                  </div>
                </div>

                <button type="submit" class="btn btn-gradient-primary me-2">
                  Submit
                </button>
                <Link to="/restaurant/staff" class="btn btn-light">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
