import React, { useState } from "react";
import BaseCard from "../../components/baseCard";
import { Box, Button, Card, CardMedia, Grid } from "@mui/material";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { addStaff } from "../../../utils";
import { useQueryClient } from 'react-query';

const validationSchema = yup.object().shape({
  name: yup.string("Enter your name").required("Name is required"),
  email: yup
    .string("Enter your email or username")
    .required("Email or username is required"),
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  phone: yup.string("Enter your phone").required("Phone no. is required"),
});

const Add = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      phone: "",
      image: "",
    },
    validationSchema: validationSchema,
    onSubmit:async (values) => {
      const myForm = new FormData();
      myForm.set("name", values.name);
      myForm.set("phone", values.phone);
      myForm.set("email", values.email);
      myForm.set("password", values.password);
      myForm.set("image", values.image);
      await addStaff(myForm)
      await queryClient.invalidateQueries("staff-listing");
      navigate(-1);
    },
  });

  const handleCoverShopImageChange = (e) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      setFile(e.target.result);
    };
    formik.setFieldValue("image", e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Staff / Add"} />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group form-group col-md-6">
                    <label for="name">Full name</label>
                    <FormGroup controlId="name" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter full name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.name && formik.errors.name}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div class="form-group form-group col-md-6">
                    <label for="email">Email</label>
                    <FormGroup controlId="email" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.email && formik.errors.email}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="phone">Phone No.</label>
                    <FormGroup controlId="phone" className="position-relative">
                      <FormControl
                        type={"text"}
                        className="form-control"
                        placeholder="Enter phone no."
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.phone && formik.errors.phone}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="phone">Password</label>
                    <FormGroup
                      controlId="password"
                      className="position-relative"
                    >
                      <FormControl
                        type={"password"}
                        className="form-control"
                        placeholder="Enter password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.password && formik.errors.password}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>
                  <div className="form-group col-md-6">
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Box display="flex" flexWrap="wrap">
                          {file && (
                            <Card
                              sx={{
                                maxWidth: 200,
                                maxHeight: 200,
                                margin: "8px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="140"
                                image={file}
                                alt={`profile image`}
                              />
                            </Card>
                          )}
                        </Box>
                      </Box>

                      <Box>
                        <input
                          type="file"
                          onChange={handleCoverShopImageChange}
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-image-upload-shop"
                        />
                        <label htmlFor="cover-image-upload-shop">
                          <Button
                            variant="contained"
                            component="span"
                            color="secondary"
                          >
                            Profile Image
                          </Button>
                        </label>
                      </Box>
                    </Grid>
                  </div>
                </div>

                <button type="submit" class="btn btn-gradient-primary me-2">
                  Submit
                </button>
                <Link to="/restaurant/staff" class="btn btn-light">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
