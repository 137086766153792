import React, { useState } from "react";
import BaseCard from "../../components/baseCard";
import { Box, Button, Card, CardMedia, Grid } from "@mui/material";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { feedAdd } from "../../../utils";
// import ReactQuill from "react-quill";
import toast from "react-hot-toast";

const validationSchema = yup.object().shape({
  type: yup
    .string("Enter your File type")
    .required("File type is a required field"),
  // description: yup
  //   .string("Enter your description")
  //   .required("Description is required"),
  image: yup.mixed().required("File is required"),
});

const Add = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      description: "",
      type: "",
      image: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const myForm = new FormData();
      myForm.set("image", values.image);
      myForm.set("type", values.type);
      myForm.set("description", values.description);
      await feedAdd(myForm).then((val) => {
        if (val?.data?.success) {
          navigate(-1);
        }
      });
    },
  });

  const handleCoverShopImageChange = (e) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      setFile(e.target.result);
    };
    formik.setFieldValue("image", e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <BaseCard title={"Feed / Add"} />
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-body">
              <form onSubmit={formik.handleSubmit} class="forms-sample">
                <div class="row">
                  <div class="form-group form-group col-md-12">
                    {/* <label for="description">Message</label> */}
                    <FormGroup
                      controlId="description"
                      className="position-relative"
                    >
                      <FormControl
                        as="textarea"
                        rows={3}
                        className="form-control"
                        placeholder="Enter message"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                      />
                      {/* <ReactQuill
                        value={formik.values.content}
                        onChange={handleEditorChange}
                      /> */}
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.description &&
                          formik.errors.description}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="exampleInputUsername1">Types</label>
                    <FormGroup controlId="type" className="position-relative">
                      <FormControl
                        as="select"
                        name="type"
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.type && Boolean(formik.errors.type)
                        }
                      >
                        <option>Select</option>
                        <option value={2} key={1}>
                          Image
                        </option>
                        <option value={3} key={2}>
                          Video
                        </option>
                      </FormControl>
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.type && formik.errors.type}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </div>

                  <div className="form-group col-md-6">
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Box display="flex" flexWrap="wrap">
                          {file && (
                            <Card
                              sx={{
                                maxWidth: 200,
                                maxHeight: 200,
                                margin: "8px",
                              }}
                            >
                              {formik.values.type === "2" ? (
                                <CardMedia
                                  component="img"
                                  height="140"
                                  image={file}
                                  alt={`profile image`}
                                />
                              ) : formik.values.type === "3" ? (
                                <video width="200" height="200" controls>
                                  <source src={file} type="video/mp4" />
                                </video>
                              ) : (
                                ""
                              )}
                            </Card>
                          )}
                        </Box>
                      </Box>

                      <input
                        type="file"
                        onChange={(e) => {
                          formik.handleChange("image")(e); // This ensures Formik handles the change
                          handleCoverShopImageChange(e); // Your custom change handler
                        }}
                        onBlur={formik.handleBlur("image")} // This ensures Formik handles the blur
                        accept={
                          formik.values.type === "2" ? "image/*" : "video/mp4"
                        }
                        style={{ display: "none" }}
                        id="cover-image-upload-shop"
                        name="image"
                        isInvalid={
                          formik.touched.image && Boolean(formik.errors.image)
                        }
                      />

                      {(formik.values.type === "2" ||
                        formik.values.type === "3") && (
                        <label htmlFor="cover-image-upload-shop">
                          <Button
                            variant="contained"
                            component="span"
                            color="secondary"
                          >
                            {formik.values.type === "2"
                              ? "Upload image"
                              : "Upload Video"}
                          </Button>
                        </label>
                      )}
                      <Form.Control.Feedback type="invalid" tooltip>
                        {formik.touched.image && formik.errors.image}
                      </Form.Control.Feedback>
                    </Grid>
                  </div>
                </div>

                <button type="submit" class="btn btn-gradient-primary me-2">
                  Submit
                </button>
                <Link to="/restaurant/feed" class="btn btn-light">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
