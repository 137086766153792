import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_FEED_ADD,
  API_FEED_COMMENT_DELETE,
  API_FEED_DELETE,
  API_FEED_LIKE_DELETE,
  API_FEED_LIST,
  API_FEED_STATUS,
  API_FEED_UPDATE,
  API_FEED_VIEW,
} from "../../utils/APIConstant";
import toast from "react-hot-toast";

export const getFeed = createAsyncThunk("get-feed", async ({token}) => {
  const res = await axios.request({
    method: "get",
    url: API_FEED_LIST,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
});

export const viewFeed = createAsyncThunk(
  "view-feed",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "get",
      url: API_FEED_VIEW + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const AddFeed = createAsyncThunk(
  "add-feed",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_FEED_ADD,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const UpdateFeed = createAsyncThunk(
  "update-feed",
  async ({ values, token }) => {
    const res = await axios.request({
      method: "PUT",
      url: API_FEED_UPDATE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: values,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const statusFeed = createAsyncThunk(
  "status-feed",
  async ({ myForm, token }) => {
    const res = await axios.request({
      method: "Post",
      url: API_FEED_STATUS,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: myForm,
    });
    toast.success(res.data.message);
    return res.data;
  }
);

export const deleteFeed = createAsyncThunk(
  "delete-feed",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_FEED_DELETE + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);
export const deleteFeedComment = createAsyncThunk(
  "delete-feed-commit",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_FEED_COMMENT_DELETE + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

export const deleteFeedLikes = createAsyncThunk(
  "delete-feed-likes",
  async ({ id, token }) => {
    const res = await axios.request({
      method: "delete",
      url: API_FEED_LIKE_DELETE + "/" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
);

const FeedSlice = createSlice({
  name: "feed",
  initialState: {
    loading: false,
    error: null,
    feed: [],
    singleFeed: "",
    isSuccessful: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFeed.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = false;
      state.feed = action.payload.data;
    });
    builder.addCase(getFeed.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(AddFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddFeed.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(AddFeed.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });


    builder.addCase(viewFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewFeed.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
      state.singleFeed = action.payload.data;
    });
    builder.addCase(viewFeed.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(statusFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusFeed.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(statusFeed.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteFeed.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessful = true;
    });
    builder.addCase(deleteFeed.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default FeedSlice.reducer;
